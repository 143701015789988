import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import CalibrationChartSearch from "components/CalibrationChartSearch/CalibrationChartSearch";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const DEVICE_TYPE = [
  { value: "HiPerForm_Plus", label: "HiPerForm_Plus" }, 
  { value: "Telos_Chiller", label: "Telos_Chiller" }, 
  { value: "Telos_Tank_EU", label: "Telos_Tank_EU" }, 
  { value: "Telos_Tank_NA", label: "Telos_Tank_NA" }, 
  { value: "Nimbus", label: "Nimbus" }, 
  { value: "Stratus", label: "Stratus" }
];
// TODO: Verify Values for Milk Cooler, Milk Tower, CP2 Chiller
const EQUIPMENT_MODELS = [
  { label: "milk_cooler", value: "MC" },
  { label: "milk_tower", value: "MT" },
  { label: "ls_chiller", value: "LS" },
  { label: "lsa_chiller", value: "LSA" }, 
  { label: "cp2_chiller", value: "CP2" },
  { label: "maxximizer", value: "HPC40" },
  { label: "hi_per_chill", value: "HPC5" },
  { label: "falling_film_chiller", value: "None" },
];

const INDUSTRY = [
  { value: "Brewing", label: "Brewing" }, 
  { value: "Dairy", label: "Dairy" }, 
  { value: "Bakery", label: "Baking" }, 
  { value: "Generic", label: "Other" }
];

const AddDeviceControlForm = memo(
  ({ show, close, dairy, addNewTank, edit, tank, editTank, dealerID, tankType }) => {
    const { t } = useTranslation();
    const schemaRegular = yup.object().shape({
      deviceName: yup.string().required(),
      deviceType: yup.string().required(),
      equipmentModelNo: yup.string().required(),
      producerID: yup.string().trim().max(50).matches(/^[a-zA-Z0-9 ]*$/),
      processedSolution: yup.string().required(),
      industry: yup.string().required(),
    });

      const schemaWithEquipmentModeNotRequired = yup.object().shape({
      deviceName: yup.string().required(),
      deviceType: yup.string().required(),
      equipmentModelNo: yup.string(),
      producerID: yup.string().trim().max(50).matches(/^[a-zA-Z0-9 ]*$/),
      processedSolution: yup.string().required(),
      industry: yup.string().required(),
    });

    const [processedSolutions, setProcessedSolutions] = useState([])
    const [equipmentModels, setEquipmentModels] = useState(EQUIPMENT_MODELS)
    const [industries, setIndustries] = useState(INDUSTRY)
    const [deviceTypes, setDeviceTypes] = useState(DEVICE_TYPE)

    const [deviceName, setDeviceName] = useState(undefined);
    const [deviceType, setDeviceType] = useState("");
    const [equipmentModelNo, setEquipmentModelNo] = useState("");
    const [producerID, setProducerID] = useState(undefined);
    const [industry, setIndustry] = useState("");
    const [processedSolution, setProcessedSolution] = useState("");
    const [calibrationData, setCalibrationData] = useState([]);
    const [schema, setSchema] = useState(schemaRegular);

    const [disabled, setDisabled] = useState(false);
    const { register, errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const onSubmit = async (data) => {
      let obj;
      if(data.deviceType === "HiPerForm_Plus") {
        obj = {
          ...data,
          tankName: data.deviceName,
          HPFSerialNumber: edit ? data.HPFSerialNumber : "",
          registrationId: edit ? data.registrationId : "",
          equipmentModelNo: edit ? data.equipmentModelNo ? data.equipmentModelNo : "" : data.equipmentModelNo,
          isOnline: false,
          macAdd: edit ? data.macAdd : "",
          calibrationChart: calibrationData && calibrationData.length ? calibrationData[0]?.id : null
        }
      } else {
        obj = {
          ...data,
          deviceSerialNumber: edit ? data.deviceSerialNumber : "",
          deviceRegistrationId: edit ? data.deviceRegistrationId : "",
          chilledSolution: "",
          isOnline: false,
          macAdd: edit ? data.macAdd : ""
        }
      }
      setDisabled(true);
      if (edit) {
        await editTank(tank.id, obj);
        setDisabled(false);
      } else {
        addNewTank(obj);
        setDisabled(false);
      }
    };

    const setProcessedSolutionList = (value) => {
      if(value === "Dairy"){
        setProcessedSolutions(["Milk"]);
      }else if(value === "Brewing"){
        setProcessedSolutions(["Beer"]);
      }else if(value === "Bakery"){
        setProcessedSolutions(["Water"]);
      }else if(value === "Generic"){
        setProcessedSolutions(["Milk", "Beer", "Water", "Ethanol", "Yeast", "Eggs"]);
      }
    }

    const onChangeIndustry = (e) => {
      const { value } = e.target;
      setIndustry(value)
      setProcessedSolutionList(value)
    }

    const onChangeDeviceType = (e) => {
      const { value } = e.target;
      setDeviceType(value)
      if(value === "HiPerForm_Plus") {
        setEquipmentModels([
          { label: "milk_cooler", value: "MC" },
          { label: "milk_tower", value: "MT" }
        ])
        setIndustries([ 
          { value: "Dairy", label: "Dairy" }
        ])
        setProcessedSolutions(["Milk"]);
      } else {
        setEquipmentModels(EQUIPMENT_MODELS)
        setIndustries(INDUSTRY)
        setProcessedSolutionList(INDUSTRY[0].value);
      }
    }

    const emptyState = () => {
      setDeviceName(undefined)
      setDeviceType('');
      setEquipmentModelNo('');
      setProducerID(undefined);
      setIndustry('');
      setProcessedSolution('');
      setCalibrationData([]);
      close()
    }

    /** Select calibration value */
    const handleChange = (value) => {
      setCalibrationData(value);
    }

    useEffect(() => {
      if(tank && tank.id){
        setDeviceTypes(DEVICE_TYPE);
        setEquipmentModels(EQUIPMENT_MODELS);
        setIndustries(INDUSTRY);
        setProcessedSolutionList(tank.deviceType ? tank.industry : "Dairy");
        setTimeout(() => {
          // checking if deviceType key is present of not (deviceType field is only present inside chiller schema and in tank)
          if(!tank.deviceType) {
            setDeviceName(tank.tankName);
            setDeviceType("HiPerForm_Plus");
            setProducerID(tank.producerID);
            setIndustry("Dairy");
            setProcessedSolution("Milk");
            // equipmentModelNo is new field added in tank schema.
            // if equipmentModelNo is not present fetch the tankType from live data and set the equipmentModelNo
            if(tank.equipmentModelNo) {
              setEquipmentModelNo(tank.equipmentModelNo);
            } else if(!tank.equipmentModelNo && tankType) {
              // TankType 1 = Vertical Tank = Milk Tower
              // TankType 0 = Horizontal Tank = Milk Cooler
              const equipmentModelNo = tankType ? "MT" : "MC";
              setEquipmentModelNo(equipmentModelNo);
            } else {
              setEquipmentModels([]);
              setEquipmentModelNo("");
              // need to set empty equipment model no when tank is offline
              setSchema(schemaWithEquipmentModeNotRequired);
            }
            // pre select calibration chart
            if (tank?.calibrationChart) {
              setCalibrationData([{
                id: tank.calibrationChart.id,
                name: tank.calibrationChart.chartName,
              }]);
            }
          } else {
            setDeviceName(tank.deviceName);
            setDeviceType(tank.deviceType);
            setEquipmentModelNo(tank.equipmentModelNo);
            setProducerID(tank.producerID);
            setIndustry(tank.industry);
            setProcessedSolution(tank.processedSolution);
          }
        }, [200])
      }
    }, [tank])

    useEffect(() => {
      // when equipmentModelNo not selected whenever we get tankType from live data will set equipmentModelNo
      if(!equipmentModelNo && tankType) {
        setEquipmentModels(EQUIPMENT_MODELS);
        const equipmentModelNo = tankType ? "MT" : "MC";
        setEquipmentModelNo(equipmentModelNo);
        setSchema(schemaRegular);
      }
    }, [tankType])

    return (
      <Modal
        show={show}
        onHide={emptyState}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className="bg-primary" closeButton>
            <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
              {edit ? t('edit_device') : t('add_device')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <Row>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("device_name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="deviceName"
                    size="lg"
                    type="text"
                    ref={register()}
                    value={deviceName}
                    onChange={(e) => setDeviceName(e.target.value)}
                    isInvalid={errors.deviceName}
                  />
                  {errors.deviceName && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("device_type")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="deviceType"
                    size="lg"
                    ref={register()}
                    value={deviceType}
                    isInvalid={errors.deviceType}
                    onChange={onChangeDeviceType}
                    disabled={edit}
                  >
                    <option value="" selected disabled></option>
                    {deviceTypes.map(({ value, label }) => (
                      <option
                        value={value}
                      >
                        {t(`${label}`)}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.deviceType && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={12}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("location_name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    value={dairy}
                    readOnly
                  />
                  {errors.locationName && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize">
                    {t("equipment_model_number")}  <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="equipmentModelNo"
                    size="lg"
                    ref={register()}
                    value={equipmentModelNo}
                    onChange={(e) => setEquipmentModelNo(e.target.value)}
                    isInvalid={!!errors.equipmentModelNo}
                    disabled={edit || !deviceType}
                  >
                    {equipmentModels.map(({ value, label }) => (
                      <option
                        value={value}
                        key={value}
                      >
                        {t(`${label}`)}
                      </option>
                    ))}
                  </Form.Control>

                  {errors.equipmentModelNo && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="producerID">
                  <Form.Label className="text-capitalize" size="lg">
                    {t("producer_id")}
                  </Form.Label>
                  <Form.Control
                    name="producerID"
                    size="lg"
                    type="text"
                    ref={register()}
                    value={producerID}
                    onChange={(e) => setProducerID(e.target.value)}
                    isInvalid={!!errors.producerID}
                    disabled={edit || !deviceType}
                  />
                  { errors.producerID &&
                    (<Form.Control.Feedback type="invalid">
                      { errors.producerID?.type === 'matches'
                        ? t('producerId_alphanumeric_error')
                        : errors.producerID?.type === 'max' && t('producerId_max_length_error') }
                    </Form.Control.Feedback>) }
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize">
                    {t("Industry")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="industry"
                    size="lg"
                    ref={register()}
                    value={industry}
                    isInvalid={!!errors.industry}
                    onChange={onChangeIndustry}
                    disabled={!deviceType || (edit && deviceType === "HiPerForm_Plus")}
                  >
                    {industries.map(({ value, label }) => (
                      <option
                        value={value}
                      >
                        {t(`${label}`)}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.industry && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label className="text-capitalize">
                    {t("processed_solution")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="processedSolution"
                    size="lg"
                    ref={register()}
                    value={processedSolution}
                    onChange={(e) => setProcessedSolution(e.target.value)}
                    isInvalid={!!errors.processedSolution}
                    disabled={!deviceType || (edit && deviceType === "HiPerForm_Plus")}
                  >
                    {processedSolutions.map((item) => (
                      <option
                        value={item}
                        key={item}
                      >
                        {t(`${item}`)}
                      </option>
                    ))}
                  </Form.Control>

                  {errors.processedSolution && (
                    <Form.Control.Feedback type="invalid">
                      {t("required_field_error")}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              {
                deviceType === "HiPerForm_Plus" ?              
                  <Col lg={12}>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                      <Form.Label className="text-capitalize">
                        {t("calibration_chart")}
                      </Form.Label>
                      <CalibrationChartSearch 
                        handleChange={handleChange}
                        dealerID={dealerID}
                        calibrationData={calibrationData}
                        disabled={!deviceType}
                        edit={edit}
                      />
                    </Form.Group>
                  </Col> : null
              }
            </Row>
          </Modal.Body>
          <Modal.Footer className="pt-0">
            <Button
              variant="primary"
              type="submit"
              className="text-capitalize"
              size="lg"
              disabled={disabled}
            >
              {edit ? t("update") : t("create")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
);

AddDeviceControlForm.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func.isRequired,
  dairy: PropTypes.string.isRequired,
  addNewTank: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  tank: PropTypes.any,
  editTank: PropTypes.func.isRequired,
};

AddDeviceControlForm.defaultProps = {
  show: false,
  edit: false,
  tank: {},
};

export default AddDeviceControlForm;
