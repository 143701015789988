import React, { useEffect, useState } from "react";
import { Drawer, Timeline, Input, Divider } from "antd";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "antd/dist/antd.css";
import timelineTick from "../../assets/images/iconAlert.svg";
import timelineAlert from "../../assets/images/iconResolved.svg";
import timelineMessage from "../../assets/images/iconComment.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/sidebar-close-icon.svg";
import ClockIcon from "../../assets/images/Icon feather-clock.svg";
import UserIcon from "../../assets/images/Icon feather-user-check.svg";
import BlueClock from "../../assets/images/blue-clock-alert.svg";
import {
  getComments,
  getChillerComments,
  postComment,
  postChillerComment,
  getUser,
  UpdateManualAnnotation,
  UpdateChillerManualAnnotation
} from "../../actions/actions";
import moment from "moment";
import Toaster from "../Toaster";
import { toast } from "react-toastify";
import { useDeviceContext } from "context/deviceContext";

const { TextArea } = Input;

const CommentBox = styled.div`
  .input-box {
    height: 4rem;
    border-radius: 4px;
    border: 1px solid #dddddd;
    outline: none;
    font-size: 15px;
  }
  .input-box:focus-within {
    border: 1px solid #3f7cca;
    height: 4rem;
    box-shadow: none;
  }
  .clear-button {
    outline: none;
    border: none;
    background: transparent;
    color: #262626;
    box-shadow: none;
  }
  .submit-button:disabled {
    background-color: #e2e2e2;
    border: 0px;
    color: #b9b9b9;
  }
  .clear-button:disabled {
    color: #b9b9b9;
  }
  .form-control:focus {
    background: #f0f6ff !important;
  }
  .description {
    margin-left: -8px;
    font-size: 15px;
    height: 2.5rem;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .description:focus {
    box-shadow: none;
  }
  .input-annotate {
    margin-left: -10px !important;
    ::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
  .title-input {
    pointer-events: none;
  }
`;

const CloseButton = styled.div`
  .close-button:hover {
    background: #d6e6ff;
    opacity: 1 !important;
    border-radius: 6px;
  }
  .close-button {
    height: 30px;
    width: 30px;
  }
`;

const TimelineWrapper = styled.div`
  padding: 0 24px 0 24px;
  img {
    position: relative;
  }
`;

const TimelineHeader = styled.div`
  margin: 20px 24px;
  font-size: 16px;
  font-weight: 500;
`;

const Sidebar = ({
  visible,
  onClose,
  title,
  alert,
  getAlerts,
  configId,
  validationTempId,
  dropdownTime,
}) => {
  const { t } = useTranslation();
  const { deviceTypeGlobal } = useDeviceContext();
  const [comment, setComment] = useState();
  const [comments, setComments] = useState([]);
  const [description, setDescription] = useState(alert?.alertText);
  const [annotationTitle, setAnnotationTitle] = useState(alert?.configDisplay);
  const [myTitle, setMyTitle] = useState(alert?.configDisplay);
  const [myDescription, setMyDescription] = useState(alert?.alertText);

  const [show, setShow] = useState(false);
  const [showDesc, setShowDesc] = useState(false);

  const [annotationTitleTemp, setAnnotationTitleTemp] = useState(
    alert?.configDisplay
  );
  const [tempDescription, setTempDescription] = useState(alert?.alertText);
  const user = getUser();

  const role = () => {
    if (alert?.createdBy.role === "SuperAdmin") {
      return t('super_admin');
    } else if (alert?.createdBy.role === "RegionalManager") {
      return t('regional_manager');
    } else if (alert?.createdBy.role === "SuperAdmin") {
      return t('super_admin');
    } else if (alert?.createdBy.role === "DairyAdmin") {
      return t('site_admin');
    } else if (alert?.createdBy.role === "DairyOperator") {
      return t('site_operator');
    } else if (alert?.createdBy.role === "MilkHauler") {
      return t('milk_hauler');
    } else {
      return alert?.createdBy.role;
    }
  };
  const getDate = (epochTime) => {
    if (dropdownTime !== undefined) {
      return moment
        .tz(new Date(epochTime), dropdownTime)
        .format(" MMMM D, hh:mm A");
    }
    return moment(epochTime).format(" MMMM D, hh:mm A");
  };

  const onClickTitle = (e) => {
    const { name } = e.target;
    if (name === "configDisplay") {
      setShow(true);
      setShowDesc(false);
    }
    if (name === "alertText") {
      setShow(false);
      setShowDesc(true);
    }
  };

  /** Hide close and done button */
  const hideButton = () => {
    setAnnotationTitle(myTitle);
    setShow(false);
    setDescription(myDescription);
    setShowDesc(false);
  };
  /** Update Manual Annotation Api call */

  const updateAnnotation = async () => {
    setMyTitle(annotationTitle);
    setMyDescription(description);
    setAnnotationTitleTemp(annotationTitle);
    setTempDescription(description);
    try {
      let data;
      if (show === true) {
        data = {
          configDisplay: annotationTitle,
        };
      } else {
        data = {
          alertText: description,
        };
      }
      const res = deviceTypeGlobal === "HPF" ? await UpdateManualAnnotation(alert?.id, data) : await UpdateChillerManualAnnotation(alert?.id, data);
      if (res.status === 200) {
        const { data } = res;
        setShow(false);
        setShowDesc(false);
        getAlerts(data);
      }
    } catch (err) {
      toast.dark(<Toaster icon="error" message={t("something_went_wrong")} />);
    }
  };

  const fetchComments = async () => {
    const res = deviceTypeGlobal === "HPF" ? await getComments(alert?.id) : await getChillerComments(alert?.id);
    if (res.status === 200 && res.data.length) {
      const userComments = res.data.map((el) => ({
        user: el.initials ? el.initials : el.user.name,
        commentText: el.commentText,
        createdAt: el.createdAt,
        icon: timelineMessage,
      }));
      const systemComments = alert?.isResolved
        ? [
            {
              icon: timelineTick,
              createdAt: alert?.updatedAt,
              user: "Resolved",
            },
          ]
        : [];
      systemComments.push({
        icon: (alert.configKey === configId || alert.configKey === validationTempId) ? BlueClock : timelineAlert,
        createdAt: alert.createdAt,
        user: (alert.configKey !== configId && alert.configKey !== validationTempId) ? "Triggered" : user.name,
      });
      userComments.push(...systemComments);
      userComments.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      setComments(userComments);
    } else {
      const userComments = alert?.isResolved
        ? [
            {
              icon: timelineTick,
              createdAt: alert?.updatedAt,
              user: "Resolved",
            },
          ]
        : [];
      userComments.push({
        icon: (alert.configKey === configId || alert.configKey === validationTempId) ? BlueClock : timelineAlert,
        createdAt: alert.createdAt,
        user: (alert.configKey !== configId && alert.configKey !== validationTempId) ? "Triggered" : user.name,
      });
      setComments(userComments);
    }
  };

  const submitComment = async () => {
    const data = {
      user: user.id,
      commentText: comment,
      alertHistory: alert?.id,
    };
    let res;
    if(deviceTypeGlobal === "HPF") {
      const data = {
        user: user.id,
        commentText: comment,
        alertHistory: alert?.id,
      };
      res = await postComment(data);
    } else if(deviceTypeGlobal === "CHILLER") {
      const data = {
        user: user.id,
        commentText: comment,
        chillerAlertHistory: alert?.id,
      };
      res = await postChillerComment(data);
    }
    if (res?.status === 200) {
      const { data } = res;
      const userComment = {
        user: data.user.name,
        commentText: data.commentText,
        createdAt: data.createdAt,
        icon: timelineMessage,
      };
      const commentsCopy = [userComment,...comments];
      setComments(commentsCopy);
      setComment("");
    }
  };

  useEffect(() => {
    if (alert) {
      fetchComments();
    }
  }, [alert]);

  const footerCommentBox = (
    alert?.configKey !== validationTempId 
    ? <>
        <div className="p-2 mr-2">
          <CommentBox>
            <TextArea
              className="mb-4 w-100 input-box"
              autoSize={{ minRows: 2, maxRows: 3 }}
              maxLength={100}
              value={comment}
              placeholder="Make a comment"
              onChange={(e) => setComment(e.target.value)}
            />
            <div className="text-right">
              <Button
                className="mr-2 clear-button"
                onClick={() => setComment("")}
                disabled={!comment}
                size="sm"
                cancel
              >
                {t("clear")}
              </Button>
              <Button
                variant="primary"
                className="submit-button"
                disabled={!comment}
                onClick={submitComment}
                size="sm"
              >
                {t("submit")}
              </Button>
            </div>
          </CommentBox>
        </div>
      </>
    : null
  );

  return (
    <Drawer
      title={title}
      placement="right"
      closable
      footer={footerCommentBox}
      onClose={onClose}
      visible={visible}
      key={alert?.id}
      width="370"
      keyboard
      destroyOnClose
      style={{ marginTop: "3.375rem" }}
      bodyStyle={{
        padding: "0",
        fontSize: "15px",
      }}
      closeIcon={
        <CloseButton>
          <span
            role="presentation"
            onClick={onClose}
            className="close-button d-flex justify-content-center align-items-center mr-n3 mt-n1"
          >
            <CloseIcon />
          </span>
        </CloseButton>
      }
    >
      {(alert.configKey === configId || alert.configKey === validationTempId) ? (
        <div className="timeline">
          <TimelineWrapper>
            <div className="pr-3 d-flex flex-column">
              {annotationTitleTemp === "Untitled" ? (
                <CommentBox>
                  <TextArea
                    name="configDisplay"
                    maxLength={50}
                    autoSize={{ minRows: 1, maxRows: 2 }}
                    value={annotationTitle}
                    className="form-control border-0 input-annotate mb-2 shadow-none"
                    onChange={(e) => setAnnotationTitle(e.target.value)}
                    onClick={(e) => onClickTitle(e)}
                  />
                  {show && (
                    <div className="text-right my-3">
                      <Button
                        className="mr-2 clear-button"
                        cancel
                        disabled={!annotationTitle}
                        size="sm"
                        onClick={hideButton}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        size="sm"
                        variant="primary"
                        className="submit-button"
                        onClick={updateAnnotation}
                        disabled={!annotationTitle}
                      >
                        {t("done")}
                      </Button>
                    </div>
                  )}
                </CommentBox>
              ) : (
                <CommentBox>
                  <TextArea
                    name="configDisplay"
                    maxLength={50}
                    autoSize={{ minRows: 1, maxRows: 2 }}
                    value={annotationTitle}
                    className="border-0 input-annotate mb-2 shadow-none title-input"
                  />
                </CommentBox>
              )}

              {tempDescription === "--" ? (
                <CommentBox>
                  <small className="mb-2 font-weight-normal text-dark-gray">
                    {t("description")}:
                  </small>
                  <TextArea
                    className="input-box form-control border-0 description"
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    maxLength={200}
                    name="alertText"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    onClick={(e) => onClickTitle(e)}
                  />
                  {showDesc && (
                    <div className="text-right my-3">
                      <Button
                        className="mr-2 clear-button"
                        onClick={hideButton}
                        disabled={!description}
                        size="sm"
                        cancel
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        className="submit-button"
                        onClick={updateAnnotation}
                        disabled={!description}
                        size="sm"
                      >
                        {t("done")}
                      </Button>
                    </div>
                  )}
                </CommentBox>
              ) : (
                <CommentBox>
                  <small className="mb-2 font-weight-normal text-dark-gray">
                    {t("description")}:
                  </small>
                  <TextArea
                    className="input-box border-0 description title-input"
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    maxLength={200}
                    name="alertText"
                    value={description}
                  />
                </CommentBox>
              )}
              <div className="my-2" style={{ fontSize: "0.9rem" }}>
                <Image
                  src={ClockIcon}
                  className="mr-1"
                  style={{ marginTop: "-4px" }}
                />
                <span style={{ color: "#343434" }}>
                  {getDate(alert?.createdAt)}
                </span>
              </div>
              <div className="my-2" style={{ fontSize: "0.9rem" }}>
                <Image src={UserIcon} className="mr-1" />
                <span className="ml-1" style={{ color: "#262626" }}>
                  {!alert["createdBy"] ||
                  alert.createdBy.name === undefined
                    ? alert.initials ? alert.initials : "--" 
                    : alert.createdBy.name}
                </span>
                {alert?.createdBy && alert?.createdBy.role && (
                  <div style={{ paddingLeft: "27px", color: "#262626" }}>
                    ({role()})
                  </div>
                )}
              </div>
            </div>
          </TimelineWrapper>
        </div>
      ) : (
        <div className="timeline">
          <TimelineWrapper>
            <div className="pr-3 d-flex flex-column ">
              <div className="mt-2">
                <TextArea
                  name="configDisplay"
                  maxLength={50}
                  autoSize={{ minRows: 1, maxRows: 2 }}
                  value={annotationTitle}
                  className="border-0 mb-2 shadow-none pl-0 font-weight-medium system-title"
                  style={{
                    pointerEvents: "none",
                  }}
                />
              </div>

              <div>
                <small className="mb-2 font-weight-normal text-dark-gray">
                  {t("description")}:
                </small>
                <TextArea
                  className="border-0 pl-0 shadow-none"
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  maxLength={200}
                  name="alertText"
                  value={description}
                  style={{
                    pointerEvents: "none",
                  }}
                />
              </div>
              <div className="my-2" style={{ fontSize: "0.8rem" }}>
                <Timeline style={{ height: "60px" }}>
                  <Timeline.Item dot={<img src={timelineAlert} alt="" />}>
                    <span style={{ color: "#343434" }}>
                      {getDate(alert?.createdAt)}
                    </span>
                  </Timeline.Item>
                  <Timeline.Item dot={<img src={timelineTick} alt="" />}>
                    <span style={{ color: "#343434" }}>
                      {alert?.isResolved === true
                        ? getDate(alert?.updatedAt)
                        : "--"}
                    </span>
                  </Timeline.Item>
                </Timeline>
              </div>
            </div>
          </TimelineWrapper>
        </div>
      )}
      <Divider />
      {
        alert.configKey !== validationTempId
        ? <div className="timeline">
            <TimelineHeader>
              <span>{t("timeline")}</span>
            </TimelineHeader>
            <TimelineWrapper>
              <Timeline>
                {comments.map((el) => {
                  return (
                    <Timeline.Item dot={<img src={el.icon} alt="" />}>
                      <div className="d-flex flex-column">
                        {(alert.configKey === configId || alert.configKey === validationTempId) ? (
                          <span
                            className="text-dark"
                            style={{
                              fontSize: "15px",
                              marginTop: "2px",
                            }}
                          >
                            {getDate(el.createdAt)}
                          </span>
                        ) : (
                          <span
                            style={{
                              fontSize: "15px",
                              color:
                                el.user === "Resolved"
                                  ? "#00a74d"
                                  : el.user === "Triggered"
                                  ? "#eb4141"
                                  : "#212f46",
                            }}
                          >
                            {getDate(el.createdAt)}
                          </span>
                        )}
                        {el.user && (
                          <span
                            style={{
                              color: "#838383",
                              fontSize: "0.8rem",
                            }}
                          >
                            {el.user}
                          </span>
                        )}
                        <span
                          className="mt-2"
                          style={{ lineHeight: "1.2rem", color: "#262626" }}
                        >
                          {el.commentText}
                        </span>
                      </div>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </TimelineWrapper>
          </div>
        : null
      }
    </Drawer>
  );
};

export default Sidebar;
