/* Canadian French Language */
export default {
  email_address: "E-mail",
  powered_by: "Alimenté par",
  send_a_link: "envoyer un lien",
  register_email:
    "Entrez votre e-mail de registre, nous vous enverrons un lien pour vérifier que vous.",
  contact_administrator_text:
    "Votre utilisateur myMueller n'a pas encore été lié au compte MilkSecure. S'il vous plaît contacter l'administrateur en envoyant un e-mail.",
  home: "Maison",
  page_not_found: "Oops, la page que vous cherchez n'existe pas.",
  add_new_region: "ajouter une nouvelle région",
  search: "Chercher",
  add_new_regional_manager: "ajouter le nouveau directeur régional",
  data_not_available: "Données non disponibles",
  regionId: "regionId",
  region_name: "nom de la région",
  regional_managers: "gestionnaires régionaux",
  user_detail: "Profil préférences",
  change_password: "changer le mot de passe",
  region: "Région",
  phone_number: "numéro de téléphone",
  dealers: "concessionnaires",
  independent_dairies: "fromageries indépendants",
  add_new_dealer: "ajouter concessionnaire",
  add_new_super_admin: "ajouter de nouveaux super-admin",
  delete_record_msg:
    "Avertissement!! La suppression des données de l'utilisateur comprometra toutes les informations des sites et appareils installés dans vos locaux. Vous ne pourrez pas récupérer les informations. Es-tu sur de vouloir continuer?",
  edit_super_admin: "modifier super-admin",
  edit_region: "modifier région",
  region_added_successfully: "région ajouté avec succès",
  something_went_wrong: "quelque chose a mal tourné",
  region_updated_successfully: "région correctement mis à jour",
  region_deleted_successfully: "région supprimé avec succès",
  super_admin_deleted_successfully: "super administrateur supprimé avec succès",
  super_admin_added_successfully: "super admin ajouté avec succès",
  super_admin_updated_successfully:
    "super administrateur mis à jour avec succès",
  regional_manager_added_successfully:
    "directeur régional a ajouté avec succès",
  regional_manager_deleted_successfully:
    "directeur régional supprimé avec succès",
  regional_manager_updated_successfully:
    "directeur régional mis à jour avec succès",
  edit_regional_manager: "modifier directeur régional",
  dealer_added_successfully: "revendeur ajouté avec succès",
  dealer_deleted_successfully: "revendeur supprimé avec succès",
  edit_dealer: "concessionnaire modifier",
  dealer_updated_successfully: "revendeur mis à jour avec succès",
  edit_independent_dairy: "modifier laiterie indépendante",
  independent_dairy_deleted_successfully:
    "produits laitiers indépendants supprimé avec succès",
  independent_dairy_updated_successfully:
    "produits laitiers indépendants mis à jour avec succès",
  producer_id: "id producteur",
  dairy_farm: "ferme laitière",
  dairy_farms: "fermes laitières",
  technicians: "techniciens",
  add_new_dairy_farm: "ajouter une nouvelle ferme laitière",
  technician_deleted_successfully: "technicien supprimé avec succès",
  add_new_technician: "ajouter le nouveau technicien",
  technician: "technicien",
  edit_technician: "technicien d'édition",
  technician_name: "nom du technicien",
  assign_dairies: "Assigner fromageries",
  technician_added_successfully: "technicien ajouté avec succès",
  dairy_updated_successfully: "produits laitiers mis à jour avec succès",
  dairy_deleted_successfully: "produits laitiers supprimé avec succès",
  technician_updated_successfully: "technicien mis à jour avec succès",
  old_password: "ancien mot de passe",
  re_enter_new_password: "ré-entrez le nouveau mot de passe",
  changed_password_message: "mot de passe défini avec succès.",
  password_reset_link_invalid: "lien Mot de passe de réinitialisation invalide",
  required_field_error: "Ce champ est requis.",
  password_length_error: "Mot de passe doit avoir 8 caractères",
  user_not_registered: "L'utilisateur n'est pas inscrit cet e-mail",
  region_id: "id région est nécessaire.",
  region_id_required: "id région est nécessaire.",
  region_name_error: "Nom de la région est nécessaire.",
  name_error: "Le nom est requis.",
  email_error: "Email est requis.",
  region_error: "Région est nécessaire.",
  password_error: "Mot de passe requis.",
  dealer_name: "Nom du concessionnaire est nécessaire.",
  dealer_email: "email revendeur est nécessaire.",
  producer_id_error: "id Le producteur est nécessaire.",
  dairy_name_error: "Nom des produits laitiers est nécessaire.",
  dairy_address: "Adresse laitière est nécessaire.",
  dairy_city: "ville laitière est nécessaire.",
  dairy_state: "État laitier est nécessaire.",
  technician_name_error: "Nom du technicien est nécessaire.",
  technician_email: "Technicien e-mail est nécessaire.",
  login_screen_text: "Connectez-vous à votre compte",
  PMC_Login: "Connectez-vous avec",
  forgot_password: "Impossible de se connecter?",
  or: "ou",
  privacy_policy: "politique de confidentialité",
  terms_and_conditions: "Termes et conditions",
  data_policy: "Politique de données",
  end_user_license_agreement: "Contrat de licence utilisateur",
  contact_administrator: "Contactez l'administrateur",
  are_you_sure: "Es-tu sur de vouloir continuer?",
  tank_mode: "Mode de réservoir de",
  last_agitated: "dernière agité",
  live_data: "Données en direct",
  re_register: "Réinscrivent",
  raw_register: "Registre brut",
  register_values: "Valeurs d'enregistrement",
  tank_updated: "Réservoir mis à jour avec succès",
  tank_deleted: "Réservoir supprimé avec succès",
  edit_dairy_farm: "Modifier ferme daity",
  create: "créer",
  update: "mettre à jour",
  regional_manager: "directeur régional",
  independent_dairy: "produits laitiers indépendants",
  language_preferences: "Préférences de langue",
  unit_preference: "Unité Pregeferences",
  temperature_unit_preference: "Unité de température",
  pressure_unit: "Unité de pression",
  volume_unit: "Unité de volume",
  alert_preference: "Préférences d'alerte",
  language_dropdown: "Langue Dropdown",
  details_updated_successfully:
    "Détails de l'utilisateur mis à jour avec succès",
  regions: "Régions",
  basic_details: "Détails de base",
  log_out: "Se déconnecter",
  login: "Connexion",
  password: "Mot de passe",
  enter_email: "E-mail",
  actions: "Actions",
  edit: "Modifier",
  delete: "Supprimer",
  add: "Ajouter",
  super_admin: "Super administrateur",
  city: "Ville",
  state: "état",
  county: "Comté / District",
  address: "Adresse",
  new_password: "Nouveau mot de passe",
  reset_password: "Réinitialiser le mot de passe",
  current_password_wrong: "Le mot de passe actuel est incorrect.",
  link_sent_successfully:
    "Lien de réinitialisation de mot de passe a été envoyé à votre adresse e-mail",
  no_data: "Aucune donnée disponible.",
  back_to_login: "Retour à l'écran de connexion",
  tank_not_registered: "Réservoir non enregistré.",
  the_dairy_owner_has_not_registered: "Le propriétaire laitier ne s'est pas inscrit.",
  tank_added: "Réservoir ajouté avec succès.",
  name: "Nom",
  email: "E-mail",
  phone: "Téléphone",
  is_registered: "Inscrit",
  dairies: "Laiteries",
  unable_to_connect_server:
    "Connexion au serveur impossible. Veuillez réessayer plus tard.",
  loading: "Chargement, veuillez patienter...",
  username_not_found: "Nom d'utilisateur introuvable.",
  tank_temperature: "Température du réservoir",
  tank_volume: "Volume du réservoir",
  pipeline_temperature: "Température pipeline",
  add_new_tank: "Ajouter un nouveau réservoir",
  num_evcs: "Nb d'EVC",
  dairy_name: "nom du producteur laitier",
  tank_name: "Nom du réservoir",
  clear: "Dégager",
  sms: "SMS",

  confirm_new_password: "Confirmer le nouveau mot de passe",
  confirm_password_required: "Confirmer mot de passe est requis.",
  dairy_added_successfully: "producteur laitier ajouté avec succès",
  dealer: "Marchand",
  enter_a_new_password: "Entrez un nouveau mot de passe",
  not_register: "Non enregistré",
  password_min: "Le mot de passe doit contenir au moins 8 à 16 caractères",
  password_not_match:
    "Le nouveau mot de passe et la confirmation de mot de passe ne sont pas identiques.",
  password_regex_message: "Mot de passe est incorrect",
  password_required: "Mot de passe requis.",
  resend_link: "lien resend",
  send_invitation_link: "Lien d'activation de compte envoyé",
  sent: "expédié",
  set_password: "mot de passe ensemble",
  set_validation_message:
    "S'il vous plaît utiliser le mot de passe entre 8 - 16 caractères qui doit inclure au moins 1 caractère numérique, 1 caractère spécial, une lettre majuscule et une minuscule sans espace.",
  user_has_been_not_registered: "L'utilisateur n'a pas encore été enregistré",
  milk_pipeline_temperature: "Pipeline lait Temp.",
  temperature_sensor_3: "Capteur de température 3",
  temperature_sensor_4: "Capteur de température 4",
  evc_temperature: "Température EVC",
  subcool_temperature: "Température de sous-refroidissement",

  Cooling: "Refroidissement",
  BU_Cool: "Refroidissement de l'Unité Inférieure (abréviation)",
  Washing: "Lavage",
  NULL: "NUL",
  INIT: "INIT",
  Power_Fail_Recovery: "Récupération de coupure d'alimentation",
  Standby: "Veille",
  Sample_Agitate: "Agitation d'échantillon",
  Editing_Parameters: "Modification des paramètres",
  Diagnostic: "Diagnostic",
  Setup: "Configuration",
  BU_Delay_Start: "délai de démarrage de l'unité inférieur",
  AU_Bottom_Delay_Start: "Retard Bas AU Démarrer",
  AU_Upper_Delay_Start: "Haut Retard AU démarrage",
  AU_Cool: "Refroidissement de Toutes les Unités (abréviation)",
  BU_Delay_BU_Pulsed_Cool:
    "Refroidissement pulsé de l'UI (abréviation), délai de mise en marche de l'UI (abréviation)",
  AU_Delay_BU_Pulsed_Cool:
    "Refroidissement pulsé de l'UI (abréviation), délai de mise en marche de TU (abréviation)",
  FW: "FW",
  AC: "CA",
  SC: "SC",
  Rinse_1_WWB: "Rinçage 1 (WWB)",
  Delay_WWB: "délai de mise en marche  (WWB)",
  Rinse_2_WWB: "Rinçage 2 (WWB)",
  Detergent_Wash_WWB: "Lavage détergent (WWB)",
  Rinse_3_WWB: "Rinçage 3 (WWB)",
  Acid_Rinse_WWB: "Rinçage acide (WWB)",
  Post_Rinse_WWB: "Post-rinçage (WWB)",
  Complete_WWB: "Terminé (WWB)",
  Rinse_1_ADB: "Rinçage 1 (ADB)",
  Delay_ADB: "délai de mise en marche (ADB)",
  Acid_Wash_ADB: "Lavage acide (ADB)",
  Rinse_2_ADB: "Rinçage 2 (ADB)",
  Detergent_Wash_ADB: "Lavage détergent (ADB)",
  Rinse_3_ADB: "Rinçage 3 (ADB)",
  Acid_Rinse_ADB: "Rinçage acide (ADB)",
  Post_Rinse_ADB: "Post-rinçage (ADB)",
  Complete_ADB: "Terminé (ADB)",
  SC_Complete_ADB: "Cycle de Désinfection (abréviation) terminé (ADB)",
  Rinse_1_MMB: "Rinçage 1 (MMB)",
  Delay_MMB: "délai de mise en marche(MMB)",
  Rinse_2_MMB: "Rinçage 2 (MMB)",
  Detergent_Wash_MMB: "Lavage détergent (MMB)",
  Rinse_3_MMB: "Rinçage 3 (MMB)",
  Acid_Rinse_MMB: "Rinçage acide (MMB)",
  Sanitize_Cycle_MMB: "Cycle de désinfection (MMB)",
  Pre_Rinse_MMB: "Pré-rinçage (MMB)",
  Complete_MMB: "Terminé (MMB)",
  SC_Complete_MMB: "Cycle de Désinfection (abréviation) terminé (MMB)",
  english: "English",
  Spanish: "Español",
  Dutch: "Nederlands",
  CanadianFrench: "Français canadien",
  Japanese: "日本語",
  German: "Deutsche",
  version: "Version",
  farm_users: "Les utilisateurs de la ferme",
  alerts: "Alertes",
  add_new_farm_users: "Ajouter un nouvel utilisateur agricole",
  role: "Rôle",
  user_role: "Type d'utilisateur Ferme",
  farm_users_added_successfully: "utilisateur ferme ajouté avec succès.",
  farm_users_deleted_successfully: "utilisateur agricole supprimé avec succès.",
  user_create_different_role_error:
    "Il y a déjà un utilisateur créé avec un rôle différent. S'il vous plaît contacter l'administrateur du site pour résoudre ce problème.",
  farm_users_updated_successfully:
    "utilisateur agricole mis à jour avec succès.",
  close: "Fermer",
  assign: "Attribuer",
  email_already_exists:
    "L'utilisateur avec l'ID de courriel mentionné existe déjà.",
  user_create_different_dairy_to_assign:
    "Il y a déjà un utilisateur créé ce rôle. Est-ce que vous voulez lui ajouter / elle à cette laiterie?",

  alert_history_error: "Erreur lors de l'extraction historique d'alerte",
  can_not_login: "Impossible de se connecter?",
  new_password_required: "Nouveau mot de passe est nécessaire.",
  old_password_required: "Ancien mot de passe est nécessaire.",
  re_enter_new_password_required:
    "Ressaisissez nouveau mot de passe est nécessaire.",
  by_signing_up:
    "En vous inscrivant, vous acceptez notre politique de confidentialité liée ci-dessous.",
  resend_email_link_expired:
    "Votre lien est arrivé à expiration le contact s'il vous plaît votre administrateur",
  configure_alerts: "Configurer les alertes",
  add_alert: "Alerte add",
  tank_name: "Nom du réservoir",
  alert_deleted_successfully: "alerte supprimé avec succès",
  alert_not_found: "Pas trouvé des alertes personnalisées.",
  sensor_name: "nom du capteur",
  sensor_value: "valeur du capteur",
  duration: "Durée",
  tank_state: "état réservoir",
  condition: "État",
  delay: "Retard",
  minutes: "Minutes",
  sensor_value_error: "Capteur est nécessaire.",
  delay_value_error: "Le délai est nécessaire.",
  duration_value_error: "La durée est nécessaire.",
  duplicate_email: "id Email double.",
  invalid_email: "Format du courrier électronique est erroné.",
  alert_added_successfully: "alerte ajouté avec succès",
  maximum_email: "Vous ne pouvez pas ajouter plus de 10 e-mails.",
  contact_number_error: "Numéro de contact est nécessaire.",
  duplicate_contact_number: "Numéro double contact",
  maximum_contact_number: "Vous ne pouvez pas ajouter plus de 10 numéros.",
  contact_digit: "Numéro de contact doit être de 10 chiffres.",
  config_alert:
    "S'il vous plaît entrer atleast un numéro de téléphone ou un e-mail pour configurer l'alerte.",
  update_form_button: "Mettre à jour",
  live_alert_not_found: "Il n'y a aucune alerte en direct.",
  live_alerts: "Alertes en direct",
  resolved_alerts: "Alertes résolue",
  live_alert_history_error:
    "Erreur lors de l'extraction d'alerte historique en direct",
  loading_text: "S'il vous plaît attendre, Chargement en cours ...",
  resolved_alert_not_found: "Aucune alerte résolus",
  triggered: "Déclenché",
  resolved: "Résolu",
  show_annotation: "Afficher l'annotation",
  hide_annotation: "Cacher Annotation",
  clear: "Dégager",
  submit: "Soumettre",
  export_csv: "Export CSV",
  add_annotation_instruction:
    "Veuillez survoler le graphique et cliquez à l'endroit où vous souhaitez ajouter l'annotation.",
  from_date_time: "De",
  to_date_time: "À",
  select_date: "S'il vous plaît sélectionner une date.",
  select_from_date: "S'il vous plaît sélectionnez De ce jour.",
  select_to_date: "S'il vous plaît Sélectionnez la date.",
  date_not_same: "La date ne peut être le même.",
  to_date_greater_than_today:
    "À ce jour ne peut pas être supérieure à aujourd'hui.",
  from_date_greater_than_today:
    "De ce jour ne peut pas être supérieure à la date à.",
  select_date_range:
    "S'il vous plaît sélectionner une plage de dates dans les 31 jours.",
  tank_data_not_available:
    "dispositif réservoir n'a pas de données entre ces dates.",
  download_failed: "Échec du téléchargement.",
  file_downloaded: "Votre fichier est en cours de téléchargement.",
  select_date_time: "Sélectionnez la date et l'heure",
  regional_managers_error: "Les directeurs régionaux est nécessaire.",
  independent_dealers: "Les revendeurs indépendants",
  cancel_re_register: "Annuler réinscrivent",
  re_registers_successfully: "Réinscrivent avec succès",
  evc_error: "EVC est nécessaire.",
  cancel_registers_successfully: "Annuler Réenregistrer avec succès",
  lsp: "Pression latérale basse",
  sst: "Température d'aspiration saturée",
  hsp: "Pression latérale élevée",
  sct: "Température de condensation",
  no_dairy_farm_data_available:
    "Il n'y a pas de telles données pour ces fermes laitières.",
  add_custom_alert: "Ajouter des alertes personnalisées",
  farm_user: "Utilisateur de la ferme",
  calibration_charts: "Tableaux d'étalonnage",
  add_new_chart: "Ajouter un nouveau tableau",
  chart_name: "Nom de graphique",
  type: "Taper",
  manufacturer: "Fabricant",
  calibration_chart: "Tableau d'étalonnage",
  create_myMueller_chart: "Créer une carte MyMueller",
  horizontal_inside_diameter: "Réservoir horizontal de diamètre intérieur",
  vertical_inside_diameter: "Tank Vertical Diamètre intérieur",
  shell_length: "Longueur de la coquille (couture à couture)",
  shell_material_thickness: "Épaisseur du matériau de coquille",
  tank_pitch_foot: "Pitcheau de réservoir par pied (pouces)",
  head_material_thickness: "Épaisseur du matériau de tête",
  head_inside_knuckle: "Tête à l'intérieur du rayon de Knuckle",
  height_increment_value: "La valeur d'incrémentation de la hauteur",
  right_head_material_thickness: "Épaisseur matérielle de la tête droite",
  right_head_inside_knuckle: "Tête droite à l'intérieur de rayon de knuckle",
  create_chart: "Créer un tableau",
  transducer_limit: "Limite de transducteur",
  calibration_deleted_successfully:
    "Tableau d'étalonnage supprimé avec succès.",
  chart_name_required: "Le nom du graphique est requis.",
  tank_type_required: "Type est requis.",
  manufacture_required: "La fabrication est requise.",
  transducer_limit_required: "La limite de transducteur est requise.",
  transducer_limit_invalid: "Limite de transducteur non valide.",
  tank_detail: "Détail de réservoir",
  calibration: "Étalonnage",
  create_new_with: "Créer de nouveau avec",
  tank_height: "Hauteur de l'appareil",
  inch: "pouce",
  gal_usa: "Gal USA",
  volume_increment: "Volume incrémentiel",
  accumulated_volume: "Volume total",
  horizontal_inside_diameter_required:
    "Le diamètre intérieur horizontal du réservoir est requis.",
  vertical_inside_diameter_required:
    "Le diamètre intérieur vertical du réservoir est requis.",
  shell_length_required: "La longueur de la coquille est requise.",
  shell_material_thickness_required:
    "L'épaisseur du matériau de la coque est requise.",
  tank_pitch_foot_required: "Le pas de réservoir par pied (pouces) est requis.",
  head_material_thickness_required:
    "L'épaisseur matérielle de la tête est requise.",
  head_inside_knuckle_required:
    "La tête à l'intérieur du rayon de knuckle est requise.",
  height_increment_value_required:
    "La valeur d'augmentation de la hauteur est requise.",
  right_head_material_thickness_required:
    "L'épaisseur matérielle de la tête droite est requise.",
  right_head_inside_knuckle_required:
    "La tête droite à l'intérieur du rayon de knuckle est requise.",
  drag_drop_file: "Faites glisser votre fichier ici our",
  just_click_browse_file: "Il suffit de cliquer pour parcourir des fichiers",
  chart_added_successfully: "Graphique ajouté avec succès.",
  invalid_excel: "Format de fichier mal téléchargé",
  calibration_chart_required: "Le tableau d'étalonnage est requis.",
  special_instruction: "Instruction spécifique",
  accumulate_height_csv_text: "Hauteur (pouce)",
  accumulate_volume_csv_text: "Volume accumulé (Gal US)",
  volume_increment_csv_text: "Incrément de volume (Gal US)",
  sample_calibration_chart: "Téléchargez le modèle de graphique d'étalonnage",
  dairy_admin_exists: "L'administrateur laitière est déjà sorties avec cette laiterie.",
  all_tanks: "Tous les réservoirs",
  producerId_max_length_error: "L'ID du producteur ne doit pas dépasser 50 caractères",
  producerId_alphanumeric_error: "Seuls les caractères alphanumériques sont autorisés",
  invalid_email_error: 'Entrer une adresse email valide',
  Agitating: 'Agitation',
  AGITATE: "AGITER",
  COOL: "COOL",
  DIAGNOSTIC: "DIAGNOSTIQUE",
  SETTINGS: "RÉGLAGES",
  STANDBY: "ETRE PRÊT",
  WASH: "LAVAGE",
  alert: "Alerte",
  tank: "Réservoir",
  tanks: "Réservoirs",
  no_manufacturer: "Aucun fabricant",
  mueller: "Mueller",
  non_mueller: "Non-mueller",
  no_type: "Sans type",
  horizontal: "Horizontal",
  vertical: "Verticale",
  dairy_owner: "Propriétaire de laiterie",
  dairy_operator: "Opérateur laitière",
  milk_hauler: "Transporteur de lait",
  sanitarian: "Sanitaire",
  HSP: "HSP",
  LSP: "LSP",
  valve_position: "Position de la vanne",
  less_than: "Moins que",
  greater_than: "Plus grand que",
  cool: "Cool",
  wash: "Lavage",
  standby: "Etre prêt",
  BOTTOM_UNIT_COOL: "Unité inférieure cool",
  ALL_UNIT_COOL: "Toute unité cool",
  BU_DELAY_BU_PULSE_COOL: "Bu retarder bu pulse cool",
  AU_DELAY_BU_PULSED_COOL: "Au retard bu pulsé cool",
  WWB_FULL_WASH_RINSE_1: "WWB Full Wash Rinse 1",
  WWB_FULL_WASH_DELAY: "Wwb délai de lavage complet",
  WWB_FULL_WASH_RINSE_2: "Wwb wash complet rincer 2",
  WWB_FULL_WASH_DETERGENT_WASH: "WWB Full Laver le détergent Lavage",
  WWB_FULL_WASH_RINSE_3: "WWB Full Wash Rinse 3",
  WWB_FULL_WASH_ACID_RINSE: "WWB Full Laver acide Rinse",
  WWB_FULL_WASH_RINSE_4: "Wwb wash complet rincer 4",
  WWB_SANITIZE_CYCLE: "WWB Sanitize Cycle",
  WWB_ACID_CYCLE_PRE_RINSE: "Cycle d'acide WWB pré-rinçage",
  WWB_ACID_CYCLE_ACID_RINSE: "Rinçage acide de cycle acide wwb",
  WWB_ACID_CYCLE_POST_RINSE: "Cycle d'acide WWB post rinçage",
  WWB_FULL_WASH_COMPLETE: "WWB Full Wash complète",
  WWB_ACID_CYCLE_COMPLETE: "Cycle d'acide WWB complet",
  WWB_SANITIZE_CYCLE_COMPLETE: "WWB Sanitize Cycle complet",
  ADB_FULL_WASH_RINSE_1: "Adb complet lavage rinçage 1",
  ADB_FULL_WASH_DELAY: "ADB Taille de lavage complet",
  ADB_FULL_WASH_ACID_WASH: "Adb complet lavage acide lavage",
  ADB_FULL_WASH_RINSE_2: "Adb pleine lavage rinçage 2",
  ADB_FULL_WASH_DETERGENT_WASH: "Lavage de détergent de lavage complet adb",
  ADB_FULL_WASH_RINSE_3: "ADB Full Wash Rinse 3",
  ADB_FULL_WASH_ACID_RINSE: "ADB Full Laver Acide Rinçage",
  ADB_FULL_WASH_RINSE_4: "ADB Full Wash Rinse 4",
  ADB_SANITIZE_CYCLE: "Adb sanitize cycle",
  ADB_ACID_CYCLE_PRE_RINSE: "ADB acide acide acide rinçage",
  ADB_ACID_CYCLE_ACID_RINSE: "Adb acide cycle post rinçage",
  ADB_ACID_CYCLE_POST_RINSE: "ADB ACID CYCLE POST RINSE",
  ADB_FULL_WASH_COMPLETE: "Adb pleine lavage complet",
  ADB_ACID_CYCLE_COMPLETE: "Cycle d'acide adb complet",
  ADB_SANITIZE_CYCLE_COMPLETE: "ADB Sanitize Cycle complet",
  MMB_FULL_WASH_RINSE_1: "MMB Full Wash Rinse 1",
  MMB_FULL_WASH_DELAY: "MMB Délai de lavage complet",
  MMB_FULL_WASH_RINSE_2: "MMB Lavage complet RINSE 2",
  MMB_FULL_WASH_DETERGENT_WASH: "Lavage de détergent de lavage complet MMB",
  MMB_FULL_WASH_RINSE_3: "MMB Lavage complet Rincer 3",
  MMB_FULL_WASH_ACID_RINSE: "MMB Full Laver acide Rinse",
  MMB_SANITIZE_CYCLE: "MMB Sanitize Cycle",
  MMB_ACID_CYCLE_PRE_RINSE: "Cycle d'acide MMB pré-rinçage",
  MMB_ACID_CYCLE_ACID_RINSE: "Mmb acide acide acide rinçage",
  MMB_FULL_WASH_COMPLETE: "MMB Lavage complet complet",
  MMB_ACID_CYCLE_COMPLETE: "Cycle d'acide MMB complet",
  MMB_SANITIZE_CYCLE_COMPLETE: "MMB Sanitize Cycle complet",
  EVC_INPUT_REG_TEMP_1: "Capteur de température EVC 1",
  EVC_INPUT_REG_TEMP_2: "Capteur de température EVC 2",
  EVC_INPUT_REG_TEMP_3: "Capteur de température EVC 3",
  EVC_INPUT_REG_SUBCOOL_TEMP: "Température inférieure",
  EVC_INPUT_REG_HI_SAT_TEMP: "Température élevée de saturation",
  EVC_INPUT_REG_LO_SAT_TEMP: "Température de saturation basse",
  EVC_INPUT_REG_HIGH_SIDE_PRESSURE: "HSP",
  EVC_INPUT_REG_LOW_SIDE_PRESSURE: "LSP",
  EVC_INPUT_REG_FAN_OUTPUT: "Vitesse du ventilateur",
  HPF_INPUT_REG_TEMP1: "Température du réservoir",
  HPF_INPUT_REG_TEMP2: "Température du pipeline de lait",
  HPF_INPUT_REG_TEMP3: "Température 3",
  HPF_INPUT_REG_TEMP4: "Température 4",
  HPF_INPUT_REG_VOLUME_4TO20MA_READING: "Volume de réservoir",
  select_user_role: "Veuillez sélectionner le rôle utilisateur",
  forget_password_description:
    "Nous enverrons un lien de réinitialisation de mot de passe vers l'e-mail vérifié de votre compte utilisateur.",
  height: "Hauteur",
  Inches: "Pouces",
  Centimeters: "Centimètres",
  Gallons: "Gallons",
  Litres: "Litres",
  Pounds: "Livres sterling",
  Kilograms: "Kilogrammes",
  chart_name_exists: 'Le nom du graphique existe déjà',
  height_unit: 'Unité de hauteur',
  device_name: "Nom de l'appareil",
  device_type: "Type d'appareil",
  location_name: "Nom de la localisation",
  equipment_model_number: "Type d’équipement",
  processed_solution: "Solution traitée",
  Industry: "Industrie",
  add_device: "Ajouter un nouvel appareil",
  edit_device: "Modifier le périphérique",
  CHILLER_CONTROLLER_INPUT_REG_TEMP_1: "Température de sortie du refroidisseur",
  CHILLER_CONTROLLER_INPUT_REG_TEMP_2: "Température de retour du refroidisseur",
  CHILLER_CONTROLLER_INPUT_REG_TEMP_3: "Température de sortie de la sortie",
  CHILLER_CONTROLLER_INPUT_REG_TEMP_4: "Traitement de la température d'entrée",
  temperature_sensor_1: "Capteur de température 1",
  temperature_sensor_2: "Capteur de température 2",
  pump: "Pompe",
  chillers: "Refroidisseurs",
  dashboard_users: "Utilisateurs du tableau de bord",
  fan_speed: "Vitesse du ventilateur",
  chiller_added: "Chiller a ajouté avec succès.",
  chiller_updated: "Chiller a mis à jour avec succès.",
  chiller_deleted: "Chiller a supprimé avec succès.",
  device_not_registered: "Appareil non enregistré",
  water_relay_status: "Statut de relais d'eau",
  voltage_input_status: "État d'entrée de tension",
  probe_sensor_status: "Statut de capteur de sonde",
  lsp_1: "Pression latérale basse 1",
  lsp_2: "Pression latérale basse 2",
  lsp_3: "Pression latérale basse 3",
  lsp_4: "Pression latérale basse 4",
  lsp_5: "Pression latérale basse 5",
  lsp_6: "Pression latérale basse 6",
  add_control: "Ajouter le contrôle",
  setting_parameters: "Paramètres de réglage",
  devices: "Dispositifs",
  process_outlet_temperature: "Température de sortie de la sortie",
  process_inlet_temperature: "Traiter la température de l'intlet",
  chiller_return_temperature: "Température de retour du refroidisseur",
  chiller_outlet_temperature: "Température de sortie du refroidisseur",
  control_status: "État du contrôle",
  send_alert_if: "Envoyer une alerte si",
  is: "est",
  when_tank_in: "Quand Tank In",
  when_chiller_in: "Quand refroidir",
  state_for_more_than: "indiquer plus de",
  INACTIVE: "INACTIF",
  SOFT_STAR_UNLOADED: "Étoile douce non chargée",
  SOFT_STAR_LOADED: "Étoile douce chargée",
  WAITING: "EN ATTENDANT",
  LOADED: "CHARGÉ",
  UNLOADED: "Déchargé",
  ANTI_SHORT_CYCLE: "Cycle anti-court",
  FREEZE_PROTECT: "Congeler la protection",
  INIT: "Init",
	STANDBY: "ETRE PRÊT",
	TECH_OVERRIDE: "Remplacement technologique",
	CHILLER_IDLE: "Refroidisseur",
	CHILLING_FLOW_SWITCH_FAILURE: "Échec de l'interrupteur de flux de refroidissement",
	CHILLING: "REFROIDISSEMENT",
	CHILLING_LOW_TEMP: "Chilant à basse température",
	CHILLING_LOW_FLOW: "Débit de refroidissement",
	PUMP_IDLE: "Pompe au ralenti",
	PUMPING_FLOW_SWITCH_FAILURE: "Pumping Flow Interrupteur",
	PUMPING: "POMPAGE",
	PUMPING_LOW_FLOW: "Pompage à faible débit",
  date: 'date',
  time: 'temps',
  timezone: 'fuseau horaire',
  sleep_mode: 'Mode veille',
  chiller_model: 'Modèle de refroidisseur',
  chiller_name: 'Nom de refroidisseur',
  company_name: "Nom de l'entreprise",
  producer_id: 'ID du producteur',
  milksecure_integration: 'Intégration de milkseure',
  version_details: 'Détails de la version',
  ip_address: 'Adresse IP',
  mac_address: 'Adresse Mac',
  language: 'langue',
  compressor_rolling_start: 'Démarrage de roulement du compresseur',
  degrees_fc: 'Degrés f / c',
  pressure_unit: 'Unité de pression',
  temperature_1_offset: 'Température 1 décalage',
  temperature_2_offset: 'Température 2 décalage',
  temperature_3_offset: 'Température 3 décalage',
  temperature_4_offset: 'Température 4 décalage',
  process_flow_meter: 'Compteur de flux de processus',
  process_velocity_max: 'Procéder Velocity Max',
  process_velocity_min: 'Procéder à la vitesse min',
  process_tubing_inside_diameter: "Tube de processus à l'intérieur du diamètre",
  process_flow_offset: 'Décalage de flux de processus',
  process_flow_unit: 'Unité de flux de processus',
  redundant_pump: 'Pompe redondante',
  pump_rotating_start: 'Démarrage de la rotation de la pompe',
  water_level_control: "Contrôle du niveau de l'eau",
  high_level_probe_sensitivity: 'Sensibilité de sonde de haut niveau',
  low_level_probe_sensitivity: 'Sensibilité de sonde à bas niveau',
  chiller_flow_meter: 'Flux de refroidisseur',
  chiller_velocity_max: 'Velocity du refroidisseur max',
  chiller_velocity_min: 'Velocity du refroidisseur min',
  chiller_solution_pipe_inside_diameter: "Tuyau de solution de refroidisseur à l'intérieur du diamètre",
  chiller_flow_offset: 'Décalage de flux de refroidisseur',
  chiller_flow_unit: 'Unité de débit de refroidisseur',
  high_temp_alert_1_setpoint: 'Haute température. Alerte 1 consigne',
  high_temp_alert_1_sensor: 'Haute température. Alerte 1 capteur',
  high_temp_alert: 'Haute température. Alerte',
  high_temp_alert_2: 'Haute température. Alerte 2',
  high_temp_alert_2_setpoint: 'Haute température. Alerte 2 consigne',
  high_temp_alert_2_sensor: 'Haute température. Alerte 2 capteur',
  high_temp_alert_delay: "Haute température. Retard d'alerte",
  high_temp_alert_duration: "Haute température. Durée d'alerte",
  low_temp_alert_setpoint: "Temp. Point de consigne d'alerte",
  low_temp_alert_1_sensor: 'Temp. Alerte 1 capteur',
  low_temp_alert: 'Temp. Alerte',
  low_temp_alert_2: 'Temp. Alerte 2',
  low_temp_alert_2_setpoint: 'Temp. Alerte 2 consigne',
  low_temp_alert_2_sensor: 'Temp. Alerte 2 capteur',
  low_temp_alert_delay: "Temp. Retard d'alerte",
  low_temp_alert_duration: "Temp. Durée d'alerte",
  flow_switch: 'Interrupteur de débit',
  water_level_alert: "Alerte au niveau de l'eau",
  water_level_alert_duration: "Durée d'alerte au niveau de l'eau",
  evc_plus: 'EVC+',
  unloader_1: 'Déchargeur 1',
  unloader_2: 'Déchargeur 2',
  anti_short_cycle: 'Cycle anti-short',
  freeze_protect_setpoint: 'CONGEZER PROTECT CONSTPOINT',
  freeze_protect_duration: 'Durée de protection',
  unsafe_suction_pressure_setpoint: "Point de consigne de pression d'aspiration dangereuxx",
  unsafe_suction_pressure_duration: "Durée de pression d'aspiration dangereuse",
  stage_1: 'Étape 1',
  stage_2: 'Étape 2',
  stage_3: 'Étape 3',
  stage_4: 'Étape 4',
  stage_5: 'Étape 5',
  stage_6: 'Étape 6',
  cool_setpoint: 'Point de consigne cool',
  cool_differential: 'Différentiel cool',
  temperature_sensor: 'Capteur de température',
  unloader_1_setpoint: 'Déchargeur 1 consigne',
  unloader_1_differential: 'Déchargeur 1 Différentiel',
  unloader_2_setpoint: 'Déchargeur 2 consigne',
  unloader_2_differential: 'Déchargeur 2 Différentiel',
  system_setup: "Installation du système",
  chiller_setup: "Configuration du refroidisseur",
  pump_setup: "Configuration de la pompe",
  alarms_alerts: "Alarmes / alertes",
  cooling_setup: "Configuration de refroidissement",
  sites: "des sites",
  site: "des sites",
  site_name: "nom du site",
  add_new_site: "Ajouter un nouveau site",
  site_added_successfully: "Le site a ajouté avec succès.",
  site_updated_successfully: "Site mis à jour avec succès.",
  site_deleted_successfully: "site supprimé avec succès.",
  site_name_error: "Le nom du site est requis.",
  site_address: "L'adresse du site est requise.",
  site_city: "La ville du site est requise.",
  site_state: "L'état du site est requis.",
  the_site_owner_has_not_registered: "Le propriétaire du site ne s'est pas inscrit.",
  user_create_different_site_to_assign: "Il y a déjà un utilisateur créé de ce rôle. Voulez-vous l'ajouter à ce site?",
  device_added: "Le périphérique a ajouté avec succès.",
  device_updated: "Appareil mis à jour avec succès.",
  device_deleted: "Dispositif supprimé avec succès.",
  independent_site: "site indépendant",
  independent_sites: "sites indépendants",
  site: "Placer",
  sites: "Des sites",
  device: "Appareil",
  devices: "Dispositifs",
  assign_sites: "Attribuer des sites",
  no_assign_sites: "Pas de sites",
  all_sites: "Tous les sites",
  add_new_dashboard_users: "Ajouter un nouvel utilisateur de tableau de bord",
  dashboard_users_added_successfully: "L'utilisateur du tableau de bord a ajouté avec succès.",
  dashboard_users_deleted_successfully: "L'utilisateur du tableau de bord a été supprimé avec succès.",
  site_admin: "Site d'administration",
  site_owner: "Propriétaire du site",
  site_operator: "Opérateur de site",
  no_site_data_available: "Il n'y a pas de telles données pour ce site.",
  dashboard_user: "Utilisateur du tableau de bord",
  active_steps: "Étapes actives",
  minute: "minute",
  height: "Hauteur",
  device_state: "État de l'appareil",
  process_flow: "Flux de processus",
  chiller_flow: "Flux de refroidissement",
  current_device_offline: "Actuellement, l'appareil est hors ligne",
  accumulator_inlet_temperature: "Température d'entrée de l'accumulateur",
  accumulator_outlet_temperature: "Température de sortie de l'accumulateur",
  accumulator_temperature_deference: "Accumulateur TD",
  EVC_FIRMWARE_VERSION: "Version du micrologiciel EVC",
  HPF_FIRMWARE_VERSION: "Version du micrologiciel HPF",
  CHILLER_FIRMWARE_VERSION: "Version du firmware de refroidissement",
  RIO_FIRMWARE_VERSION: "Version du micrologiciel Rio",
  EVC_NUMBER: "Numéro EVC",
  HPF_NUMBER: "Numéro HPF",
  CHILLER_NUMBER: "Numéro de refroidisseur",
  HiPerForm_Plus: "Hiperform Plus",
  Telos_Chiller: "Refroidisseur",
  Telos_Tank_EU: "Tank Milkseure (UE)",
  Telos_Tank_NA: "Tank Milkseure (NA)",
  Nimbus: "Nimbus",
  Stratus: "Stratus",
  milk_cooler: "Melkkoeler",
  milk_tower: "Melktoren",
  ls_chiller: "LS Chiller",
  lsa_chiller: "LSA Chiller",
  cp2_chiller: "CP2 -koeler",
  maxximizer: "MAXXimizer",
  hi_per_chill: "HiPerChill",
  falling_film_chiller: "落下フィルムチラー",
  Brewing: "Brouwen",
  Dairy: "Zuivel",
  Bakery: "Bakkerij",
  Other: "Autres",
  Milk: "Lait",
  Beer: "Bière",
  Water: "Eau",
  Ethanol: "Éthanol",
  Yeast: "Levure",
  Eggs: "Œufs",
  no_site_admin: "Aucun administrateur site",
  ALARM:'ALARME'
};
