import React, { memo, useState, useEffect, Fragment } from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import _ from "lodash";

import Edit from "../../assets/images/edit.svg";
import Delete from "../../assets/images/delete.svg";
import { useTranslation } from "react-i18next";
import { getTanks, getDeviceAlerts, deleteAlert, deleteChillerAlert, fetchConfigs, getLastWashCycleValue, getDevices } from "../../actions/actions";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Toaster from "../../components/Toaster";
import PromptModel from "components/PromptModel/PromptModel";
import AddDeviceAlertForm from "../../forms/AlertForm/AddDeviceAlertForm";
import EditDeviceAlertForm from "../../forms/AlertForm/EditDeviceAlertForm";
import AlertLoader from "../../components/AlertLoader";
import {
  chillerStateArr,
  tankStateArr,
} from "../../actions/passwordRegex";


import { getAlertValueBasedOnUnitPreference } from "../../actions/passwordRegex";

const Alerts = memo(() => {
  const { t } = useTranslation();
  const { dairyID } = useParams();
  const [alerts, setAlerts] = useState([]);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selectId, setSelectedId] = useState(null);
  const [display, setDisplay] = useState(false);
  const [user, setUser] = useState(null);
  const [tankData, setTankData] = useState([]);
  const [tankStates, setTankStates] = useState(tankStateArr);
  const [sensorData, setSensorData] = useState([]);
  const [alertLoader, setAlertLoader] = useState(false);
  const [deviceType, setDeviceType] = useState(undefined)
  const [selectedAlert, setSelectedAlert] = useState(undefined)

  const getAlertListings = () => {
    setAlertLoader(true);
    getDeviceAlerts(dairyID)
      .then((response) => {
          setAlertLoader(false);
          setAlerts(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  // Filter tank state based on last wash cycle
  const filterTankState = (lastWashCycleData) => {
    if (lastWashCycleData && lastWashCycleData > 3000) {
      const filteredArray = {};
        for (let key in tankStateArr) {
          if (lastWashCycleData >= 3000 && lastWashCycleData < 3100 && key >= 3000 && key < 3100) {
            filteredArray[key] = tankStateArr[key];
          }
          else if (lastWashCycleData >= 3100 && lastWashCycleData < 3200 && key >= 3100 && key < 3200) {
            filteredArray[key] = tankStateArr[key];
          } else if (lastWashCycleData >= 3200 && key >= 3200) {
            filteredArray[key] = tankStateArr[key];
          }
        }
        return { ...filteredArray, cool: t('cool'), wash: t('wash'), standby: t('standby'), 2003: t('BOTTOM_UNIT_COOL'), 2004: t('ALL_UNIT_COOL'), 2005: t('BU_DELAY_BU_PULSE_COOL'), 2006: t('AU_DELAY_BU_PULSED_COOL') }
    }
    return tankStateArr;
  }

  // Get last wash cycle data
  const getLastWashCycleValueData = async (tankId) => {
    const lastWashCycle = await getLastWashCycleValue(tankId)
    const filterTanks = filterTankState(lastWashCycle.data.max)
    return filterTanks;
  }

  // Get tank data
  const getTankData = () => {
    getDevices(dairyID)
      .then((deviceLists) => {
        if (deviceLists.status === 200) {
          setTankData([...deviceLists.data]);
        }
        return deviceLists;
      })
      .then((deviceLists) => {
        if(deviceLists?.data[0]?.deviceType){
          setTankStates(chillerStateArr);
          setDeviceType("CHILLER");
        } else {
          getLastWashCycleValue(deviceLists.data[0].id)
            .then((lastWashCycle) => {
              const filterTanks = filterTankState(lastWashCycle.data.max)
              setTankStates(filterTanks);
              setDeviceType("HPF");
            })
        } 
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSensorData = () => {
    fetchConfigs()
      .then((response) => {
        if (response.status === 200) {
          const sensorsList = response.data ? response.data.filter((sensor) => sensor.unit !== "") : [];

          // Translating display name respective to the selected language
          sensorsList.length && sensorsList.forEach((sensor) => {
            sensor.displayName = t(sensor.key);
          });

          setSensorData(sensorsList);
          localStorage.setItem("configs", JSON.stringify(sensorsList));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getAlertListings();
    getSensorData();
    getTankData();
  }, []);

  useEffect(() => {
    if(tankStates && sensorData && deviceType && Object.keys(tankStates).length && sensorData.length) {
      const tempSensorData = deviceType === "HPF"
      ? sensorData.filter((sensor) => sensor.key.includes("HPF") || sensor.key.includes("EVC")) 
      : sensorData.filter((sensor) => sensor.key.includes("CHILLER") || sensor.key.includes("EVC"))
      setSensorData(tempSensorData);
      setDeviceType(undefined);
    }
  }, [tankStates, sensorData, deviceType])

  /** Delete Region api call */
  const deleteRecord = () => {
    const id = selectedAlert?.id;
    if(selectedAlert?.tank && id) {
      deleteAlert(id)
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);
            setSelectedAlert(undefined)
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("alert_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getAlertListings();
          }
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    } else if (selectedAlert?.device && id) {
      deleteChillerAlert(id)
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);
            setSelectedAlert(undefined);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("alert_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getAlertListings();
          }
        })
        .catch((err) => {
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response?.message ?? t("something_went_wrong")}
            />
          );
        });
    }
  };

  /** Added and update data after update state data */
  const updatedList = (item, action) => {
    if (action === "add") {
      setAlerts([item, ...alerts]);
    }
    if (action === "edit") {
      setAlerts(alerts.map((i) => (i.id === item.id ? item : i)));
    }
  };
  return (
    <>
      <Row>
        <Col
          xs={12}
          lg={3}
          md={8}
          sm={7}
          className="mb-4 d-flex align-items-center"
        >
          <h2 className="text-primary mb-0">{t("configure_alerts")}</h2>
        </Col>
        <Col
          xs={12}
          lg={9}
          md={4}
          sm={5}
          className="d-inline-flex justify-content-end mb-4"
        >
          <Button
            className="text-capitalize"
            variant="primary"
            onClick={() => setShow(true)}
          >
            {t("add_custom_alert")}
          </Button>
        </Col>
      </Row>
      <div className="py-2 border border-light rounded">
        {alertLoader ? (
          <AlertLoader />
        ) : (
            alerts.length < 1 ? (
              <div className="text-center lead">{t("data_not_available")}</div>
            ) : (
                alerts.map((alert, i) => {
                  return (
                    alert.tank ?
                    <Fragment key={i}>
                      <Row className="m-0 px-3 pt-3 pb-3 row" key={i}>
                        <Col lg={10} md={9} sm={12} xs={12}>
                          <div className="mb-2">
                            {t('send_alert_if')} {t(alert.config.key)} {t('is')}
                            {alert.condition === "lt" ? ` ${t('less_than')} ` : ` ${t('greater_than')} `}
                            {getAlertValueBasedOnUnitPreference(
                              alert.value,
                              alert.config.unit,
                              "valueAndUnit",
                              "HPF"
                            )}{" "}
                    {t('when_tank_in')} {tankStateArr[alert.tankState]} {t('state_for_more_than')}
                            {" "}{alert.duration}{" "}
                            {alert.duration === 1 ? ` ${t('minute')} ` : ` ${t('minutes')} `}
                          </div>
                          <div className="mb-2">
                            <span className="text-dark-gray">
                              {alert?.tank && alert?.tank?.tankName
                                ? t("tank_name") + " : "
                                : t("all_tanks")}
                            </span>
                            <span>{alert?.tank?.tankName}</span>
                          </div>
                          {
                            alert.specialInstruction ? (
                              <div className="mb-2">
                                <span className="text-dark-gray">
                                  {t("special_instruction") + " : "}
                                </span>
                                <span>{alert?.specialInstruction}</span>
                              </div>
                            ) : ("")
                          }
                        </Col>
                        <Col
                          lg={2}
                          md={3}
                          sm={12}
                          xs={12}
                          className="d-flex align-items-start justify-content-end"
                        >
                          <Button
                            variant="link"
                            className="table-edit-button text-capitalize text-black"
                            onClick={async () => {
                              setUser(alert);
                              const filteredTanks = await getLastWashCycleValueData(alert?.tank?.id)
                              setTankStates(filteredTanks)
                              setDisplay(true);
                            }}
                          >
                            <Image src={Edit} />
                          </Button>
                          <Button
                            variant="link"
                            className="delete-button text-danger text-capitalize"
                            onClick={() => {
                              setSelectedAlert(alert);
                              setOpen(true);
                            }}
                          >
                            <Image src={Delete} />
                          </Button>
                        </Col>
                      </Row>
                      <hr className="mx-4 mt-0" />
                    </Fragment>
                    :
                    <Fragment key={i}>
                      <Row className="m-0 px-3 pt-3 pb-3 row" key={i}>
                        <Col lg={10} md={9} sm={12} xs={12}>
                          <div className="mb-2">
                            {`${t('send_alert_if')} `}
                            {
                              alert.config.key 
                              ? alert.config.key === "EVC_INPUT_REG_TEMP_1"
                                ? t('accumulator_inlet_temperature')
                                : alert.config.key === "EVC_INPUT_REG_TEMP_2" 
                                  ? t('accumulator_outlet_temperature')
                                  : t(alert.config.key)
                              : null
                            } 
                            {` ${t('is')}`}
                            {alert.condition === "lt" ? ` ${t('less_than')} ` : ` ${t('greater_than')} `}
                            {getAlertValueBasedOnUnitPreference(
                              alert.value,
                              alert.config.unit,
                              "valueAndUnit",
                              "CHILLER"
                            )}{" "}
                    {t('when_chiller_in')} {chillerStateArr[alert.deviceState]} {t('state_for_more_than')}
                            {" "}{alert.duration}{" "}
                            {alert.duration === 1 ? ` ${t('minute')} ` : ` ${t('minutes')} `}
                          </div>
                          <div className="mb-2">
                            <span className="text-dark-gray">
                              {alert?.device && alert?.device?.deviceName
                                ? t("tank_name") + " : "
                                : t("all_tanks")}
                            </span>
                            <span>{alert?.device?.deviceName}</span>
                          </div>
                          {
                            alert.specialInstruction ? (
                              <div className="mb-2">
                                <span className="text-dark-gray">
                                  {t("special_instruction") + " : "}
                                </span>
                                <span>{alert?.specialInstruction}</span>
                              </div>
                            ) : ("")
                          }
                        </Col>
                        <Col
                          lg={2}
                          md={3}
                          sm={12}
                          xs={12}
                          className="d-flex align-items-start justify-content-end"
                        >
                          <Button
                            variant="link"
                            className="table-edit-button text-capitalize text-black"
                            onClick={async () => {
                              setUser(alert);
                              setTankStates(chillerStateArr)
                              setDisplay(true);
                            }}
                          >
                            <Image src={Edit} />
                          </Button>
                          <Button
                            variant="link"
                            className="delete-button text-danger text-capitalize"
                            onClick={() => {
                              setSelectedAlert(alert);
                              setOpen(true);
                            }}
                          >
                            <Image src={Delete} />
                          </Button>
                        </Col>
                      </Row>
                      <hr className="mx-4 mt-0" />
                    </Fragment>
                  );
                })
              )
          )}
      </div>

      {show && (
        <AddDeviceAlertForm
          showModel={show}
          closeModel={() => setShow(false)}
          updateList={updatedList}
          sensorData={sensorData}
          tankData={tankData}
          tankStates={tankStates}
          chillerState={chillerStateArr}
          getLastWashCycleValueData={getLastWashCycleValueData}
        />
      )}

      {display && (
        <EditDeviceAlertForm
          showModel={display}
          closeModel={() => setDisplay(false)}
          alertData={user}
          updateList={updatedList}
          tankData={tankData}
          tankStates={tankStates}
        />
      )}

      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
          setSelectedAlert(undefined);
        }}
        modelContent={t("delete_record_msg")}
        callback={() => deleteRecord(selectedAlert?.id)}
      />
    </>
  );
});

export default Alerts;
