import styled from "styled-components";
import React, { memo, useEffect, useState } from "react";
import EVC_GIF_ICON from "../../assets/images/EVC-fan-gif.gif";
import EVC_ICON from '../../assets/images/EVC-fan.png';
import { getFlexWidth } from "../../actions/passwordRegex";
import {
  getStringFromDecimalToBinary
} from "../../helpers";
import CommonDisplay from "./CommonDisplay";


const GraphTypeWrapper = styled.div`
  // overflow: ${({ numDevices }) => (numDevices >= 7 ? "scroll" : "initial")};
  // white-space: ${({ numDevices }) => (numDevices >= 7 ? "nowrap" : "initial")};
  // flex: ${({ numDevices }) => `0 0 ${getFlexWidth(numDevices)}%`};
  // img {
  //   position: relative;
  //   width: 35px;
  //   cursor: pointer;
  // }
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const TankDevices = memo(
  ({
    currentTankData,
    tankType,
    setDeviceId,
    deviceType,
    deviceTypeGlobal,
    deviceId,
    setDeviceType,
    deviceIdObj,
    tankDeviceType,
    setCurrentActiveView,
    chillerAlertsList
  }) => {
    const [currentEnableDeviceIDs, setCurrentEnableDeviceIDs] = useState(deviceIdObj);
    const [isFanRotating, setIsFanRotating] = useState(Array.from('0'.repeat(6)));
    const [showAlertIcon, setShowAlertIcon] = useState({});
    const [evcAlertsByDeviceID, setEvcAlertsByDeviceID] = useState([])

    useEffect(() => {
      // filtering only enable devices whenever live data emits
      if (currentTankData?.CHILLER && deviceIdObj?.CHILLER?.length && deviceIdObj?.EVC?.length && Object.keys(currentTankData.CHILLER).length) {
        const EVCDeviceIdsList = [];
        for (let i = 0; i < 6; i++) {
          const isCompressorEnable = Number(currentTankData?.CHILLER?.[deviceIdObj?.CHILLER?.[0]]?.[`CHILLER_CONTROLLER_HOLD_REG_STAGE_${i + 1}_ENABLED_PARAM`])

          if (isCompressorEnable) {
            EVCDeviceIdsList.push({ evcDeviceID: deviceIdObj.EVC[i], compressorIndex: i, deviceNo: currentTankData?.EVC[deviceIdObj.EVC[i]]?.device });
          }
                  }
        const relayStatusCompressorBits = getStringFromDecimalToBinary(currentTankData?.CHILLER?.[deviceIdObj?.CHILLER?.[0]]?.[`CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS`]).slice(4, 10)
        setCurrentEnableDeviceIDs({ ...deviceIdObj, "EVC": EVCDeviceIdsList });
                setIsFanRotating(relayStatusCompressorBits)
      }

      // when device is offline and on page load setting evc obj
      if(!Object.keys(currentTankData).length && Object.keys(deviceIdObj).length && deviceIdObj?.EVC?.length) {
        const EVCDeviceIdsList = [];
        for(let i = 0; i < deviceIdObj.EVC.length; i++) {
          EVCDeviceIdsList.push({ evcDeviceID: deviceIdObj.EVC[i], compressorIndex: null })
        }
        setCurrentEnableDeviceIDs({ ...deviceIdObj, "EVC": EVCDeviceIdsList });
      }
    }, [currentTankData, deviceIdObj]);

    function detectAlerts() {
      if (!chillerAlertsList.length) {
        // NO ALERTS FOUND;
        return;
      }
      const alertsByEvcDeviceID = []
      for (let compressorIndex = 0; compressorIndex < 6; compressorIndex++) {
        if (!chillerAlertsList.length) {
          setShowAlertIcon(Object.assign(showAlertIcon, { [compressorIndex]: false }));
          break;
        }
        for (let chillerAlert of chillerAlertsList) {
          if (chillerAlert?.alertType?.includes('INDIVIDUAL') && (
            chillerAlert?.configKey === `CHILLER_CONTROLLER_INPUT_REG_ALARMS_ACTIVE_1_BIT_${compressorIndex + 10}` ||
            chillerAlert?.configKey === `CHILLER_CONTROLLER_INPUT_REG_ALARMS_ACTIVE_2_BIT_${compressorIndex}`
          )) {
            setShowAlertIcon(Object.assign(showAlertIcon, { [compressorIndex]: true }));
            break;
          }

          setShowAlertIcon(Object.assign(showAlertIcon, { [compressorIndex]: false }));
        }
      }
      for (const chillerAlert of chillerAlertsList) {
        if(chillerAlert.deviceType === "EVC") {
          if(chillerAlert.alertType.includes("INDIVIDUAL") && chillerAlert.configKey.includes("EVC_INPUT_STATUS_REGISTER")) {
            alertsByEvcDeviceID.push(chillerAlert.deviceSerialNumber);
          }
        }
      }
      setEvcAlertsByDeviceID(alertsByEvcDeviceID);
    }

    useEffect(() => {
      detectAlerts();
    }, [chillerAlertsList]);

    return (
      <GraphTypeWrapper
        numDevices={
          deviceIdObj ? deviceIdObj.EVC.length + (deviceTypeGlobal === "HPF" ? deviceIdObj.HPF.length : deviceIdObj.CHILLER.length) : 0
        }
      >
        {
          deviceTypeGlobal === "HPF"
            ?
            (deviceIdObj &&
              Object.keys(deviceIdObj).map((key) => {
                return deviceIdObj[key].map((id, index) => {
                  const evcIcon = Number.parseInt(currentTankData?.EVC?.[id]?.RUN_SIGNAL) !== 0 ? EVC_GIF_ICON : EVC_ICON;

                  return (
                    <CommonDisplay
                      deviceId={deviceId}
                      id={id}
                      deviceKey={key}
                      deviceTypeGlobal={deviceTypeGlobal}
                      setDeviceId={setDeviceId}
                      setDeviceType={setDeviceType}
                      setCurrentActiveView={setCurrentActiveView}
                      deviceType={deviceType}
                      tankType={tankType}
                      evcIcon={evcIcon}
                      tankDeviceType={tankDeviceType}
                      currentTankData={currentTankData}
                    />
                  );
                });
              }))
            : deviceTypeGlobal === "CHILLER" ?
              (currentEnableDeviceIDs &&
                Object.keys(currentEnableDeviceIDs).map((key) => {
                  if (key === "CHILLER") {
                    return currentEnableDeviceIDs[key].map((id, index) => {
                      return (
                        <CommonDisplay
                          deviceId={deviceId}
                          id={id}
                          deviceKey={key}
                          deviceTypeGlobal={deviceTypeGlobal}
                          setDeviceId={setDeviceId}
                          setDeviceType={setDeviceType}
                          setCurrentActiveView={setCurrentActiveView}
                          deviceType={deviceType}
                          tankType={tankType}
                          tankDeviceType={tankDeviceType}
                          currentTankData={currentTankData}
                        />
                      );
                    });
                  }
                  return currentEnableDeviceIDs[key].map(({ evcDeviceID, compressorIndex, deviceNo }, index) => {
                    const evcRotateSignal = Number(isFanRotating[index])
                    const evcDisabledSignal = Number.parseInt(currentTankData?.CHILLER?.[deviceIdObj?.CHILLER?.[0]]?.[`CHILLER_CONTROLLER_HOLD_REG_COMPRESSOR_${deviceNo}_EVC_ENABLED_PARAM`]) === 0
                    const evcIcon = evcRotateSignal ? EVC_GIF_ICON : EVC_ICON;
                    const isIndividualEvcAlertResolved = evcAlertsByDeviceID.includes(evcDeviceID);
                    const alertIcon = showAlertIcon[compressorIndex] || isIndividualEvcAlertResolved || false;
                    let stageIndex = Number(currentTankData?.CHILLER?.[deviceIdObj?.CHILLER?.[0]]?.[`CHILLER_CONTROLLER_INPUT_REG_STAGE_${deviceNo}_COMPRESSOR`]) + 1;
  
                    return (
                      <CommonDisplay
                        deviceId={deviceId}
                        id={evcDeviceID}
                        deviceKey={key}
                        deviceTypeGlobal={deviceTypeGlobal}
                        evcDisabledSignal={evcDisabledSignal}
                        index={compressorIndex}
                        setDeviceId={setDeviceId}
                        setDeviceType={setDeviceType}
                        setCurrentActiveView={setCurrentActiveView}
                        deviceType={deviceType}
                        tankType={tankType}
                        evcIcon={evcIcon}
                        stageIndex={stageIndex}
                        tankDeviceType={tankDeviceType}
                        currentTankData={currentTankData}
                        alertIcon={alertIcon}
                      />
                    );
                  });
                })) : null
        }

      </GraphTypeWrapper>
    );
  }
);

export default TankDevices;
