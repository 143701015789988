import { get } from "jquery";
import { rootApi, graphApi } from "../axiosConfig";
const GRAPH_DATA_URL = process.env.REACT_APP_SensorDataAPI;

export const LOCAL_STORAGE_VAR = {
  USER : 'user',
  CONFIGS : 'configs'
}

/**  getting item from local storage */
export function getItem(itemName){
  if(!itemName){
    return null
  }

  return JSON.parse(localStorage.getItem(itemName))
}

export function getUser() {
  return getItem(LOCAL_STORAGE_VAR.USER);
}

/** Get Configs from local storage */
export function getConfigs(){
  return getItem(LOCAL_STORAGE_VAR.CONFIGS);
}

const queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + (params[key] || ""))
    .join("&");
};

/** Login API Call */
export function login(email, password) {
  return rootApi.post(`/login`, {
    email,
    password,
  });
}

/** ForgotPassword API Call */
export function forgotPassword(email) {
  return rootApi.post(`/forgotpassword`, {
    email,
  });
}
/** Changed password Api call */
export function changePassword(data) {
  return rootApi.post(`/changepassword`, data);
}
/** Reset Password api call */
export function resetPassword(password) {
  const request = rootApi.post(`/resetpassword`, password);
  return request;
}

/** Add Region API Call */
export function addRegion(data) {
  return rootApi.post(`/regions`, data);
}

/** Add RegionalManagers API Call */
export function addRegionalManagers(user) {
  return rootApi.post(`/users`, user);
}

/** Add RegionalManagers API Call */
export function getRegionalManagers() {
  return rootApi.get(`/users?role=RegionalManager&skip=0&limit=1000`);
}

/** Get Regions API Call */
export const getRegions = () => {
  return rootApi.get(`/regions?skip=0&limit=1000`);
};

/** Get Region from RegionID */
export const fetchRegionById = (regionId) => {
  return rootApi.get(`/regions/${regionId}`);
};

/** Get SuperAdmins API Call */
export const getSuperAdmin = () => {
  return rootApi.get(`/users?role=SuperAdmin&skip=0&limit=1000`);
};
/** Get dealer list API Call */
export function getDealerList(regionID) {
  return rootApi.get(
    `/customUserQuery?region=${regionID}&role=Dealer&withDairies=true&sort=createdAt%20desc`
  );
}
/** Fetch dealer By Id */

export const fetchUserById = (id) => {
  return rootApi.get(`/users/${id}`);
};

/** Get Independent Dairies list api call */
export function getIndependentDairiesList() {
  return rootApi.get(`/dairies?where={"createdBy":null}&skip=0&limit=1000`);
}
/** Delete Super admin and regional Manager Api call */
export function deleteSuperAdmin(id) {
  return rootApi.delete(`/users?id=${id}`);
}

/** Delete Region Api call */
export function deleteRegion(id) {
  return rootApi.delete(`/regions/${id}`);
}

/** Update Existing super admin Api call */
export function updateUser(id, data) {
  return rootApi.patch(`/users/${id}`, data);
}
/** Update region api call */
export function updateRegion(id, data) {
  return rootApi.patch(`/regions/${id}`, data);
}
/** Update Dairy api call */
export function updateDairy(id, data) {
  return rootApi.patch(`/dairies/${id}`, data);
}
/** Get dairyList api call */
export function getDairyList() {
  return rootApi.get(`/customUserQuery?role=Dealer&sort=createdAt%20desc`);
}
/** Delete dairy api call */
export function deleteDairy(id) {
  return rootApi.delete(`/dairies/${id}`);
}
/** Get all dairy admin list */
export function getDairyAdmin() {
  return rootApi.get(`/users?role=DairyAdmin&skip=0&limit=1000`);
}
/** Get Technician as per the dealerID Api call */
export const getTechnician = (id) => {
  return rootApi.get(
    `/customUserQuery?createdBy=${id}&role=Technician&sort=createdAt%20desc`
  );
};

/** Get Dairies as per the Dairy Id Api call */
export function fetchDairyById(id) {
  return rootApi.get(`/dairies/${id}`);
}

/** Fetch Dairies by userID Api call */
export function fetchDairiesByUserId(userId) {
  return rootApi.get(
    `/users?createdBy=${userId}&role=DairyAdmin&sort=createdAt%20desc&skip=0&limit=1000`
  );
}
/** Add dairy data api call */
export function addDairy(data) {
  return rootApi.post(`/dairies`, data);
}
/** Fetch DairyBy Id */
export function fetchDairy(id) {
  return rootApi.get(`/dairies/${id}`);
}

/** Fetch DairyBy Id */
export function DisplayDairyData(dealerID) {
  const user = getUser();
  if(user.role === "Technician") {
    return rootApi.get(`/dairies?sort=createdAt%20desc&skip=0&limit=1000`);
  } else if (user.role === "DairyAdmin") {
    return rootApi.get(`/sites`);
  }
  return rootApi.get(`/sites/${dealerID}`);
}
/** Toggle dairy monitoring api call */
export function toggleDairyMonitoring(isBeingMonitored, userId, dairyId) {
  // Make API call to add or remove monitoring of this dairy
  const URL = `/users/${userId}/dairiesToMonitor/${dairyId}`;
  switch (isBeingMonitored) {
    case true:
      return rootApi.delete(URL);
    case false:
      return rootApi.put(URL, {});
    default:
      return false;
  }
}

// API call to get tanks
export const getTanks = (dairyId) => {
  return rootApi.get(`/tanks?dairy=${dairyId}`);
};

// API call to get Tank's HPF and EVC id
export const getTankHPFandEVCId = (tankId) => {
  return graphApi.get(
    `/tankDevices?tankID=${tankId}&token=${localStorage.getItem("token")}`
  );
};

export const getTankCHILLERandEVCId = (chillerId) => {
  return graphApi.get(
    `/chiller-evc-devices?chillerObjectId=${chillerId}&token=${localStorage.getItem("token")}`
  );
};

// API call to get Tank's last agitation value
export const getLastAgitatedValue = (tankId, HPFId) => {
  return graphApi.get(
    `/lastAgitated?token=${localStorage.getItem("token")}&tankID=${tankId}&deviceID=${HPFId}`
  );
};

// API call to get Tank's last wash cycle
export const getLastWashCycleValue = (tankId) => {
  return graphApi.get(
    `/lastWashCycle?token=${localStorage.getItem("token")}&tankID=${tankId}`
  );
};

// API call to get Tank's register value for the graph
export function fetchGraph(
  timeLimit,
  deviceType,
  sensors,
  tankId,
  deviceId,
  startDateTime,
  endDateTime
) {
  return graphApi.get(
    `/registerValues?duration=${timeLimit}&deviceType=${deviceType}&sensors=${sensors.join()}&tankID=${tankId}&deviceID=${deviceId}&token=${localStorage.getItem("token")}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`
  );
}

export function fetchGraphChiller(
  timeLimit,
  deviceType,
  sensors,
  chillerObjectId,
  deviceSerialNumber,
  startDateTime,
  endDateTime
) {
  return graphApi.get(
    `/graph-data?duration=${timeLimit}&deviceType=${deviceType}&sensors=${sensors.join()}&chillerObjectId=${chillerObjectId}&deviceSerialNumber=${deviceSerialNumber}&token=${localStorage.getItem("token")}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`
  );
}

/** Add tank api call */
export function addTank(data) {
  return rootApi.post(`/tanks`, data);
}

/** Delete tank api call */
export function deleteTank(id) {
  return rootApi.delete(`/tanks/${id}`);
}

/** Edit tank api call */
export function updateTank(id, data) {
  return rootApi.patch(`/tanks/${id}`, data);
}

/** Get tank api call */
export function getTankById(id) {
  return rootApi.get(`/tanks/${id}`);
}
/** LogOut function */
export function logOut() {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  window.location.href = "/login";
}
/** Send resend Activation link api call */
export function postResendUserActivationLink(email) {
  return rootApi.post(`/resendActivationLink`, email);
}
/** Version summary api call */
export function fetchVersion() {
  return rootApi.get(`/version`);
}
/** Check if email is already Exist or not */
export function checkIfUserExistsByEmail(email) {
  return rootApi.get(
    `/checkIfUserExistsByEmail?email=${encodeURIComponent(email)}`
  );
}
/** Already assign farm users */
export function addAlreadyRegisteredFarmUser(dairyID, userID) {
  return rootApi.put(`/dairies/${dairyID}/farmUsers/${userID}`);
}
/** Delete unassign farm user */
export function unassignFarmUser(userID, dairyID) {
  return rootApi.delete(`/dairies/${dairyID}/farmUsers/${userID}`);
}
/** Fetch users */
export function fetchUsers(params) {
  return rootApi.get(
    `/users?${queryString({ ...params, sort: "createdAt desc" })}`
  );
}

/** Get alert api call */

export function getAlert(id) {
  return rootApi.get(`/alerts?dairy=${id}&sort=createdAt desc`);
}
/** Delete alert api call */
export function deleteAlert(id) {
  return rootApi.delete(`/alerts/${id}`);
}
/** Get sensor name api call */
export function fetchConfigs() {
  return rootApi.get(`/configs?sort=createdAt desc`);
}
/** Add alerts api call */
export function addAlert(alert) {
  return rootApi.post(`/alerts`, alert);
}
/** Update alert api call */
export function updateAlert(id, alert) {
  return rootApi.patch(`/alerts/${id}`, alert);
}

// Get Comments on an alert
export const getComments = (alertId) => {
  return rootApi.get(`/alertTimelines`, {
    params: {
      alertHistory: alertId,
      sort: "createdAt desc",
    },
  });
};

//Post Comments on an alert
export const postComment = (data) => {
  return rootApi.post(`/alertTimelines`, data);
};

//Get alerts based on tank
export const getTankAlerts = (tankId, startTime, endTime) => {
  return rootApi.get(`/alerthistories`, {
    params: {
      where: {
        tank: tankId,
        alert: null,
        createdAt: { ">": startTime, "<": endTime },
      },
      limit: "10000",
    },
  });
};
/** Export csv api call */

export function exportGraph(
  getCurrentTankId,
  hpfDeviceIds,
  evcDeviceIds,
  fromDate,
  toDate,
  userToken,
  user
) {
  return fetch(
    `${GRAPH_DATA_URL}/exportData?tankID=${getCurrentTankId}&hpfDeviceIds=${hpfDeviceIds}&evcDeviceIds=${evcDeviceIds.join(
      ","
    )}&startDateTime=${fromDate}&endDateTime=${toDate}&token=${userToken}&user=${user}`
  );
}

export function getTankCount(tankId, userToken) {
  return fetch(
    `${GRAPH_DATA_URL}/tankDevices?tankID=${tankId}&token=${userToken}`
  );
}

//Independent Dealers List

export const getIndependentDealersList = () => {
  return rootApi.get("customUserQuery", {
    params: {
      role: "Dealer",
      withDairies: true,
      sort: "createdAt desc",
    },
  });
};

/** Add manual Annotation Api call */

export const postManualAnnotation = (data) => {
  return rootApi.post(`/AlertHistories`, data);
};

/** Update Manual Annotation Api call */
export function UpdateManualAnnotation(alertId, data) {
  return rootApi.patch(`/AlertHistories/${alertId}`, data);
}
/** Delete Manual Annotation Api call */
export function DeleteManualAnnotation(alertId) {
  return rootApi.delete(`/AlertHistories/${alertId}`);
}

/** Drag and drop index api call */

export function dragDropTank(id, data) {
  return rootApi.patch(`/tanks/${id}`, data);
}
/** Display calibration chart Listing api call */
export const getCalibrationListing = (dealerID) => {
  return rootApi.get(`/tankcharts?dealer=${dealerID}&isDeleted=false&sort=createdAt desc`);
};

/** Delete calibration chart Listing api call */
export function deleteCalibrationChart(id) {
  return rootApi.patch(`/tankcharts/${id}?isDeleted=true`);
}

/** Add new chart Api call */

export const postTankChart = (data) => {
  return rootApi.post(`/tankcharts`, data);
};

/** Chillers apis */
export const addNewChillerController = (data) => {
  return rootApi.post(`/chillers`, data);
};

// API call to get Chillers
export const getChillers = (dairyId) => {
  return rootApi.get(`/chillers?locationName=${dairyId}`);
};

// API call to get chiller by ID
export function getChillerById(id) {
  return rootApi.get(`/chillers/${id}`);
}

/** Edit chiller api call */
export function updateChiller(id, data) {
  return rootApi.patch(`/chillers/${id}`, data);
}

/** Delete tank api call */
export function deleteChiller(id) {
  return rootApi.delete(`/chillers/${id}`);
}

/** Drag and drop index api call for chiller */
export function dragDropChiller(id, data) {
  return rootApi.patch(`/chillers/${id}`, data);
}

//Get alerts based on tank
export const getChillerAlerts = (tankId, startTime, endTime) => {
  return rootApi.get(`/chilleralerthistories`, {
    params: {
      where: {
        device: tankId,
        chillerAlert: null,
        createdAt: { ">": startTime, "<": endTime },
      },
      limit: "10000",
    },
  });
};
/** Get alert api call */
export function getChillerAlert(id) {
  return rootApi.get(`/chillerAlerts?location=${id}&sort=createdAt desc`);
}
/** Add alerts api call */
export function addChillerAlert(alert) {
  return rootApi.post(`/chillerAlerts`, alert);
}
/** Update alert api call */
export function updateChillerAlert(id, alert) {
  return rootApi.patch(`/chillerAlerts/${id}`, alert);
}
/** Delete alert api call */
export function deleteChillerAlert(id) {
  return rootApi.delete(`/chillerAlerts/${id}`);
}

export function exportChillerGraph(
  getCurrentTankId,
  evcDeviceIds,
  fromDate,
  toDate,
  userToken,
  user
) {
  return fetch(
    `${GRAPH_DATA_URL}/export-chiller-data?chillerObjectId=${getCurrentTankId}&evcDeviceSerialNumbers=${evcDeviceIds.join(
      ","
    )}&startDateTime=${fromDate}&endDateTime=${toDate}&token=${userToken}&user=${user}`
  );
}

export const postChillerManualAnnotation = (data) => {
  return rootApi.post(`/ChillerAlertHistories`, data);
};

/** Update Manual Annotation Api call */
export function UpdateChillerManualAnnotation(alertId, data) {
  return rootApi.put(`/ChillerAlertHistories/${alertId}`, data);
}
/** Delete Manual Annotation Api call */
export function DeleteChillerManualAnnotation(alertId) {
  return rootApi.delete(`/ChillerAlertHistories/${alertId}`);
}
// Get Comments on an alert
export const getChillerComments = (alertId) => {
  return rootApi.get(`/chillerAlertTimelines`, {
    params: {
      chillerAlertHistory: alertId,
      sort: "createdAt desc",
    },
  });
};

//Post Comments on an alert
export const postChillerComment = (data) => {
  return rootApi.post(`/chillerAlertTimelines`, data);
};

// Update Number of EVCs using Device (Tank / Chiller) Object ID
export const UpdateNoOfEVCsByID = (id, data) => {
  return rootApi.patch(`/device-update-evc/${id}`, data);
};

/** Get device by id */
export function getDeviceByID(id) {
  return rootApi.get(`/deviceByID/${id}`);
}

/** Get all devices */
export function getDevices(dairyID) {
  return rootApi.get(`/devices/${dairyID}`);
}

/** Get all device alerts */
export function getDeviceAlerts(dairyID) {
  return rootApi.get(`/device-alerts/${dairyID}`);
}

/** delete or unassign farm user */
export function deleteOrUnAssignFarmUser(userID, dairyID) {
  return rootApi.post(`/delete-unassign-dairy-owner/${dairyID}/${userID}`);
}