import React, { memo, useState, useMemo } from 'react'
import styled, { css } from "styled-components";
import _ from "lodash"
import { Image } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  getTempAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
} from "../../helpers";
import { registerNames, GRAPH_VIEW } from "../../actions/passwordRegex";

import tankVerticalIcon from "../../assets/images/vertical-tank.svg";
import TankHPF from "../../assets/images/Tank002.png";
import { ReactComponent as TelosChillerIcon } from "../../assets/images/Telos_Chiller.svg";
import { ReactComponent as NimbusIcon } from "../../assets/images/Nimbus.svg";
import { ReactComponent as StratusIcon } from "../../assets/images/Stratus.svg";
import { ReactComponent as HiperformIcon } from "../../assets/images/HiPerForm_Plus.svg";

const GraphTypeSubWrapper = styled.div`
${({ active, disabled, isMain, evcDisabledSignal }) => css`
  padding: 7px 5px;
  border-width: 1px;
  /* border-color: transparent; */
  border-color: ${disabled ? '#DDDDDD' : '#D6E6FF'};
  border-style: solid;

  ${isMain ? css`
    height: 55px;
    width: 55px;
    border-radius: 4px;
    /* border-color: ${disabled ? '#DDDDDD' : '#3F7CCA'}; */
    /* border-bottom: {active ? "3px solid #2c61a5" : "none")}; */
    background-color: {active ? "#f0f6ff" : "white"};
    display: inline-block;
    img {
      max-height: 100%;
      width: auto !important;
    }
    `: css`
      height: 44px;
      width: 44px;
      /* background-color: ${evcDisabledSignal ? '#EDEDED' : ''};  */
      border-radius: 2px;
      ${evcDisabledSignal && css`
        &, & * {
          cursor: not-allowed !important;
          pointer-events: none;
        }
      `}

      img {
        max-width: 100% !important;
      }
    `}
  ${active && !disabled && css`
    border-color: #337FF7 !important;
    background-color: #F0F6FF;
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.12);
  `}
  ${!active && !evcDisabledSignal && css`
    border-color: #D6E6FF;
  `}
`}
`;

const DeviceTabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  cursor: ${({ evcDisabledSignal }) => evcDisabledSignal ? 'not-allowed' : 'pointer'};
  .device-label {
    margin-top: 1px;
    font-size: 12px;
    text-align: center;
    ${({ active }) => active && css`
      font-weight: bold;
      font-size: 10px;
      padding: 2px 4px;
      border-radius: 2px;
      background-color: #D3E6FF;
    `};
  }
`;

const DeviceContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor:pointer;
`;

const CommonDisplay = ((
{
    deviceId, 
    id, 
    deviceKey,
    evcDisabledSignal,
    deviceTypeGlobal,
    index,
    setDeviceId,
    setDeviceType,
    deviceType,
    tankType,
    evcIcon,
    stageIndex,
    tankDeviceType,
    currentTankData,
    setCurrentActiveView,
    alertIcon
  }) => {
  const [hoveredData, setHoveredData] = useState("");
  const [indicator, setIndicator] = useState("");
  const { t } = useTranslation();
  let configs = JSON.parse(localStorage.getItem("configs"));
  configs = configs.filter((config, index, self) => {
  return (index === self.findIndex(i => i.key === config.key));
  });

    const tempBarData = [
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_1",
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_TEMP2",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "EVC_INPUT_REG_SUBCOOL_TEMP",
      "EVC_INPUT_REG_HIGH_SIDE_PRESSURE",
      "EVC_INPUT_REG_LOW_SIDE_PRESSURE",
  ];

  const TankChillerIcon = useMemo(() => {
      switch (tankDeviceType) {
          case "HiPerForm_Plus":
          return HiperformIcon;
          case "Telos_Chiller":
          return TelosChillerIcon;
          case "Telos_Tank_EU":
          return TelosChillerIcon;
          case "Telos_Tank_NA":
          return TelosChillerIcon;
          case "Nimbus":
          return NimbusIcon;
          case "Stratus":
          return StratusIcon;
          default:
          return () => '--';
      }
  }, [tankDeviceType]);

  const tooltipData = (hoveredDeviceType, hoveredDeviceId) => {
    if (
      currentTankData &&
      currentTankData[hoveredDeviceType] &&
      currentTankData[hoveredDeviceType][hoveredDeviceId]
    ) {
      if (hoveredDeviceType === "CHILLER") {
        hoveredDeviceId = Object.keys(currentTankData[hoveredDeviceType])[0];
      }
      setHoveredData(currentTankData[hoveredDeviceType][hoveredDeviceId]);
      let indicatorItem = currentTankData[hoveredDeviceType][hoveredDeviceId]
        .EVC_IDENTIFICATION
        ? currentTankData[hoveredDeviceType][hoveredDeviceId]
          .EVC_IDENTIFICATION
        : "";
      setIndicator(indicatorItem);
    }
    else {
      setHoveredData('');
    }
  };

  return (
    <OverlayTrigger
      key={id}
      placement="top"
      overlay={
        hoveredData
          ? <Tooltip
            id={`tooltip-${id}`}
            className={`device-tool-tip ${deviceKey==="EVC" && evcDisabledSignal ? 'device-tooltip--disabled':''}`}
            >
              <>
                {
                  deviceTypeGlobal === "HPF" 
                  ? _.invert(registerNames)[indicator] !== undefined &&
                    <div className="evc-tooltip-title">{_.invert(registerNames)[indicator]}</div>
                  : deviceTypeGlobal === "CHILLER" && deviceKey === "EVC" && !evcDisabledSignal &&
                    <div className="evc-tooltip-title">Compressor - {!Number.isNaN(index) ? index + 1 : ''}</div>
                }
                {configs.map((configKey) =>
                  Object.keys(hoveredData).map((temp, i) => {
                    if (deviceKey==="EVC" && evcDisabledSignal) return null;
                      if (configKey.key === temp) {
                        let sensorValue = hoveredData[temp];
                        let sensorUnit = configKey.unit;

                        if (configKey.key.includes("TEMP") && configKey.key.includes("CHILLER")) {
                          const temperatureSensor = Number(hoveredData["CHILLER_CONTROLLER_INPUT_REG_ALARMS_ACTIVE_1"]) || 0;
                          const chillerOutletTempFailure = ((temperatureSensor >> 3) & 1)

                          let temperaturePerUnit = ""
                          if(temp === "CHILLER_CONTROLLER_INPUT_REG_TEMP_1"){
                            temperaturePerUnit = getTempAsPerSelectedUnitSystem(Number(hoveredData[temp]),undefined,chillerOutletTempFailure, undefined, hoveredData[temp]);
                          }else{
                            temperaturePerUnit = getTempAsPerSelectedUnitSystem(Number(hoveredData[temp]));
                          }
                          

                          sensorValue = temperaturePerUnit.temperature || "--";
                          sensorUnit = temperaturePerUnit.tempUnit || "";
                        } else if (configKey.key.includes("TEMP")) {
                          const {
                            temperature,
                            tempUnit,
                          } = getTempAsPerSelectedUnitSystem(Number(hoveredData[temp]), configKey.key.includes("SUBCOOL"));

                          sensorValue = temperature;
                          sensorUnit = tempUnit;
                        } else if (configKey.key === "HPF_INPUT_REG_VOLUME_4TO20MA_READING") {
                          const {
                            volume,
                            volumeUnit,
                          } = getVolumeAsPerSelectedUnitSystem(Number(hoveredData[temp]));

                          sensorUnit = volumeUnit;
                          sensorValue = volume;
                        } else if (configKey.key.includes("PRESSURE")) {
                          const {
                            pressure,
                            pressureUnit,
                          } = getPressureAsPerSelectedUnitSystem(Number(hoveredData[temp]));

                          sensorUnit = pressureUnit;
                          sensorValue = pressure;
                        }
                        return (
                          <div className="tooltip-line-item" key={i}>
                            {tempBarData.indexOf(temp) > -1 && (
                              <div
                                className="diffTempValues"
                                key={temp}
                              >
                                <span>
                                  {
                                    deviceType === "HPF" 
                                    ? configKey.displayName
                                    : configKey.key === "EVC_INPUT_REG_TEMP_1"
                                      ? t('accumulator_inlet_temperature')
                                      : configKey.key === "EVC_INPUT_REG_TEMP_2" 
                                        ? t('accumulator_outlet_temperature')
                                        : configKey.displayName
                                  
                                  }:&nbsp;
                                </span>
                                <span className="hoveredNum">
                                  {` ${sensorValue} `}&nbsp;{sensorUnit}
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      }
                    return "";
                  })
                )}
              </>
            </Tooltip>
          : <Tooltip
              id={`tooltip-${id}`}
              className={'device-tool-tip'}
            >
              {
                deviceTypeGlobal === "CHILLER" && deviceKey === "EVC" &&
                <div className="evc-tooltip-title">Compressor - {!Number.isNaN(index) ? index + 1 : ''}</div>
              }
              {t('no_data')}
            </Tooltip>
      }
    >
      <DeviceTabWrapper role="presentation"
        active={deviceId === id}
        evcDisabledSignal={evcDisabledSignal}
        onClick={() => {
          if ((deviceType === deviceKey && deviceId === id) || (deviceKey === "EVC" && evcDisabledSignal)) {
            return;
          }
          setDeviceType(`${deviceKey}`);
          setDeviceId(`${id}`);
          setCurrentActiveView(GRAPH_VIEW)
        }}>
        <GraphTypeSubWrapper
          active={deviceId === id}
          disabled={tankType === null}
          isMain={deviceKey === "HPF" || deviceKey === "CHILLER"}
          evcDisabledSignal={evcDisabledSignal}
          onMouseOver={(event) => tooltipData(deviceKey, id)} onFocus={() => { }}>
          {tankType === null ? (
            <div
              className="active offline offlineTank pt-1 pb-1"
            >
              <span
                className={
                  deviceKey === "HPF" || deviceKey === "CHILLER" ? "single-tank-page" : "evc-img"
                }
                at-data={id}
              >
                --
              </span>
            </div>
          ) : (
            <DeviceContainer
              className={deviceId === id ? 'active' : ''}>
              {deviceKey === 'CHILLER' ? <TankChillerIcon /> :
                deviceKey === 'HPF' 
                ? <Image
                    className={deviceKey === "HPF" ? tankType == "1" ? "vertical-image" : "hpf-image" : "evc-image"}
                    src={
                      deviceKey === "HPF"
                        ? tankType == "1"
                          ? tankVerticalIcon
                          : TankHPF
                        : evcIcon
                    }
                  />
                : <span className={`${alertIcon ? 'alert-red-indicator position-relative' : 'text-primary'}`}>
                    <Image
                      className={deviceKey === "HPF" ? tankType == "1" ? "vertical-image" : "hpf-image" : "evc-image"}
                      src={
                        deviceKey === "HPF"
                          ? tankType == "1"
                            ? tankVerticalIcon
                            : TankHPF
                          : evcIcon
                      }
                    />
                  </span>
              }
            </DeviceContainer>
          )}
        </GraphTypeSubWrapper>

        {deviceKey === "EVC" && stageIndex ? <span className="device-label">{`Stage ${stageIndex}`}</span> : null}
      </DeviceTabWrapper>
    </OverlayTrigger>
  )
}
);

export default CommonDisplay