import React, { memo, useState, useCallback, useEffect } from "react";
import { Row, Col, Button, Form, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import Datetime from "react-datetime";
import { getUser, exportGraph, exportChillerGraph, getTankCount, getTankCHILLERandEVCId } from "../../actions/actions";
import "react-datetime/css/react-datetime.css";
import timezoneList from "timezones.json";
import Toaster from "../../components/Toaster";
import { toast } from "react-toastify";
import { useDeviceContext } from '../../context/deviceContext'
const { Parser } = require("json2csv");

const ExportCsvForm = memo(({ showModel, closeModel, tank, dropdownTime }) => {
  const { t } = useTranslation();
  const userToken = localStorage.getItem("token");
  const { deviceTypeGlobal } = useDeviceContext()
  const [loader, setLoader] = useState(false);
  const [exportCsvTo, setExportCsvTo] = useState("");
  const [exportCsvFrom, setExportCsvFrom] = useState("");
  const [exportDeviceIds, setExportDevicesIdes] = useState({});
  const user = getUser();
  const systemData = localStorage.getItem("time");

  let systemTime = moment.tz.guess();
  
  const systemTimeZone = dropdownTime === undefined ? systemTime : dropdownTime;

  const fetchTankData = useCallback(async () => {
    if(deviceTypeGlobal === "HPF") {
      let deviceIdsList = await getTankCount(tank.id, userToken);
      deviceIdsList = await deviceIdsList.json();

      if (deviceIdsList.HPF && deviceIdsList.HPF.length > 0) {
        deviceIdsList["HPF"] = deviceIdsList["HPF"].slice(
          deviceIdsList["HPF"].length - 1
        );
      }
      setExportDevicesIdes(deviceIdsList);
    } else if(deviceTypeGlobal === "CHILLER"){
      let deviceIdsList = await getTankCHILLERandEVCId(tank.id, userToken);
      deviceIdsList = deviceIdsList.data;
      if (deviceIdsList.CHILLER && deviceIdsList.CHILLER.length > 0) {
        deviceIdsList["CHILLER"] = deviceIdsList["CHILLER"].slice(
          deviceIdsList["CHILLER"].length - 1
        );
      }
      setExportDevicesIdes(deviceIdsList);
    }

  }, []);

  useEffect(() => {
    fetchTankData();
  }, []);

  const onChangeDateTimeTo = (value) => {
    setExportCsvTo(value._d);
  };

  const onChangeDateTimeFrom = (value) => {
    setExportCsvFrom(value._d);
  };

  /** Refactor json object function  */
  var addToObject = function (obj, key, value, index) {
    // Create a temp object and index variable
    var temp = {};
    var i = 0;
    // Loop through the original object
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        // If the indexes match, add the new item
        if (i === index && key && value) {
          temp[key] = value;
        }
        // Add the current item in the loop to the temp obj
        temp[prop] = obj[prop];
        // Increase the count
        i++;
      }
    }
    // If no index, add to the end
    if (!index && key && value) {
      temp[key] = value;
    }
    return temp;
  };

  const exportCSV = async () => {
    let getCurrentTankId;
    let dairyName;
    let tankName;
    let producerID;
    let fileType;
    if(deviceTypeGlobal === "HPF") {
      getCurrentTankId = tank.id;
      dairyName = tank.dairy.name;
      tankName = tank.tankName;
      producerID = tank.dairy.producerID;
      fileType = "HPF_";
    } else if( deviceTypeGlobal === "CHILLER") {
      getCurrentTankId = tank.id;
      dairyName = tank.locationName.name;
      tankName = tank.deviceName;
      producerID = tank.locationName.producerID;
      fileType = "CHILLER_";
    }
    const differenceDate = moment(exportCsvTo).diff(
      moment(exportCsvFrom),
      "days"
    );
    if (!exportCsvFrom && !exportCsvTo) {
      return toast.dark(<Toaster icon="error" message={t("select_date")} />, {
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
    if (!exportCsvFrom) {
      return toast.dark(
        <Toaster icon="error" message={t("select_from_date")} />,
        {
          autoClose: 2000,
          hideProgressBar: true,
        }
      );
    }
    if (!exportCsvTo) {
      return toast.dark(
        <Toaster icon="error" message={t("select_to_date")} />,
        {
          autoClose: 2000,
          hideProgressBar: true,
        }
      );
    }
    if (exportCsvFrom.toString() === exportCsvTo.toString()) {
      return toast.dark(<Toaster icon="error" message={t("date_not_same")} />, {
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
    if (exportCsvTo.toISOString() > new Date().toISOString()) {
      return toast.dark(
        <Toaster icon="error" message={t("to_date_greater_than_today")} />,
        {
          autoClose: 2000,
          hideProgressBar: true,
        }
      );
    }
    if (exportCsvFrom > exportCsvTo) {
      return toast.dark(
        <Toaster icon="error" message={t("from_date_greater_than_today")} />,
        {
          autoClose: 2000,
          hideProgressBar: true,
        }
      );
    }
    if (differenceDate >= 31) {
      return toast.dark(
        <Toaster icon="error" message={t("select_date_range")} />,
        {
          autoClose: 2000,
          hideProgressBar: true,
        }
      );
    }
    const fromDate = new Date(exportCsvFrom);
    const toDate = new Date(exportCsvTo);

    const fromDateFormat = `${`0 ${fromDate.getMonth() + 1}`.slice(
      -2
    )}-${`0${fromDate.getDate()}`.slice(
      -2
    )}-${fromDate.getFullYear()}_${`0${fromDate.getHours()}`.slice(
      -2
    )}-${`0${fromDate.getMinutes()}`.slice(-2)}`;

    const toDateFormat = `${`0${toDate.getMonth() + 1}`.slice(
      -2
    )}-${`0${toDate.getDate()}`.slice(
      -2
    )}-${toDate.getFullYear()}_${`0${toDate.getHours()}`.slice(
      -2
    )}-${`0${toDate.getMinutes()}`.slice(-2)}`;

    // Initialize the loader
    await setLoader(!loader);

    let data;
    // // get HPF and EVC device IDS
    let hpfDeviceIds;
    let evcDeviceIds = [];
    if (exportDeviceIds !== undefined) {
      hpfDeviceIds = deviceTypeGlobal === "HPF" && exportDeviceIds["HPF"] && exportDeviceIds["HPF"][0];
      evcDeviceIds = exportDeviceIds.EVC;
    }
    try {
      // Make API call to get the data
      if(deviceTypeGlobal === "HPF") {
        const response = await exportGraph(
          getCurrentTankId,
          hpfDeviceIds,
          evcDeviceIds,
          fromDate.toISOString(),
          toDate.toISOString(),
          userToken,
          user.role
        );
        data = await response.json();
      } else if(deviceTypeGlobal === "CHILLER") {
        const response = await exportChillerGraph(
          getCurrentTankId,
          evcDeviceIds,
          fromDate.toISOString(),
          toDate.toISOString(),
          userToken,
          user.role
        );
        data = await response.json();
      }

      setLoader(false);
      if (data[0].length === 0) {
        toast.dark(
          <Toaster icon="error" message={t("tank_data_not_available")} />,
          {
            autoClose: 2000,
            hideProgressBar: true,
          }
        );
        setLoader(false);
      } else {
        for (let i = 0; i < data.length; i += 1) {
          if (data[i] && data[i][0]) {
            const fileName = `${fileType}${fromDateFormat}${"_to_"}${toDateFormat}${".csv"}`;
            fileType = "EVC_";
            const evcIdentificationNumber = data[i][i]["EVC_IDENTIFICATION"];
            let deviceEVCNumber = "";
            if (evcIdentificationNumber && evcIdentificationNumber === 32) {
              deviceEVCNumber = "| EVC Number: Bottom Unit #1";
            } else if (
              evcIdentificationNumber &&
              evcIdentificationNumber === 33
            ) {
              deviceEVCNumber = "| EVC Number: Bottom Unit #2";
            } else if (
              evcIdentificationNumber &&
              evcIdentificationNumber === 34
            ) {
              deviceEVCNumber = "| EVC Number: Side Unit #3";
            } else if (
              evcIdentificationNumber &&
              evcIdentificationNumber === 35
            ) {
              deviceEVCNumber = "| EVC Number: Side Unit #4";
            } else {
              deviceEVCNumber = "";
            }
            let csvData = data[i].map((row) => {
              let utcTime = moment
                .tz(new Date(row[deviceTypeGlobal === "HPF" || fileName.includes("EVC") ? "Date/Time (GMT)" : "time"]), systemTimeZone)
                .format("YYYY/MM/DD HH:mm:ss (z) (Z)");
                let title = moment
                .tz(new Date(row[deviceTypeGlobal === "HPF" || fileName.includes("EVC") ? "Date/Time (GMT)" : "time"]), systemTimeZone)
                .format("(z)");
                var row = addToObject(row, "Date Time " + title, utcTime, 1);
              return row;
            });
            const json2csvParser = new Parser();
            let csv = json2csvParser.parse(csvData);
            csv = deviceTypeGlobal === "HPF" 
                  ? `Dairy Name: ${dairyName} | Tank Name: ${tankName} | ProducerID: ${producerID} ${deviceEVCNumber}\n` + csv
                  : `Location : ${dairyName} | Device Name: ${tankName} | ProducerID: ${producerID} ${deviceEVCNumber}\n` + csv
            // Few Browsers won't allow to download multiple files once, to avoid that implemented setTimeout
            setTimeout(() => {
              const blobdata = new Blob([csv], { type: "text/csv" });
              const link = document.createElement("a");
              link.setAttribute("href", window.URL.createObjectURL(blobdata));
              link.setAttribute("download", fileName);
              document.body.appendChild(link); // Required for FF
              link.click();
            }, 500)
            setLoader(false);
          }
        }
        toast.dark(
          <Toaster
            className="text-capitalize"
            icon="download"
            message={t("file_downloaded")}
          />,
          { autoClose: 2000, hideProgressBar: true }
        );
        closeModel();
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
      toast.dark(<Toaster icon="error" message={t("download_failed")} />, {
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  };

  return (
    <Modal
      show={showModel}
      onHide={closeModel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="bg-primary" closeButton>
        <Modal.Title className="lead text-capitalize text-white mb-0 font-weight-bold">
          {t("export_csv")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col lg={12} className="mb-3 d-flex">
            <div style={{ color: "#838383" }} className="mr-1">
              {t("time_zone")}:
            </div>
            <div>{systemData}</div>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="formBasicName">
              <Form.Label className="text-capitalize" size="lg">
                {t("from_date_time")}
              </Form.Label>{" "}
              <Datetime
                className="align-text-left calender-icon"
                onChange={onChangeDateTimeFrom}
                disableOnClickOutside={false}
                timeFormat="hh:mm A"
                inputProps={{
                  placeholder: t("select_date_time"),
                  disabled: false,
                  className: "form-control form-control-lg calender",
                  readOnly: true,
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="formBasicName">
              <Form.Label className="text-capitalize" size="lg">
                {t("to_date_time")}
              </Form.Label>{" "}
              <Datetime
                className="align-text-left calender-icon"
                onChange={onChangeDateTimeTo}
                disableOnClickOutside={false}
                timeFormat="hh:mm A"
                inputProps={{
                  placeholder: t("select_date_time"),
                  disabled: false,
                  className: "form-control form-control-lg calender",
                  readOnly: true,
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="pt-0 mb-5">
        <Button
          variant="primary"
          type="submit"
          className="text-capitalize"
          size="lg"
          onClick={() => exportCSV()}
        >
          {loader ? (
            <>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            t("export_csv")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

ExportCsvForm.propTypes = {
  showModel: PropTypes.bool,
  closeModel: PropTypes.func.isRequired,
};

ExportCsvForm.defaultProps = {
  showModel: false,
};
export default ExportCsvForm;
