import React, { useEffect, useState, useRef } from "react";
// import Dygraph from "dygraphs";
import Dygraph from "dygraphs/index.es5";
// eslint-disable-next-line
import Plugins from "dygraphs/src/extras/synchronizer";
// eslint-disable-next-line
import Hairlines from "dygraphs/src/extras/hairlines";
import Crosshair from "./CrossHair";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import "bootstrap-daterangepicker/daterangepicker.css";
import styled from "styled-components";
import { Row, Col, Image } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import CustomCheckbox from "../CustomCheckbox";
import volumeIcon from "../../assets/images/Volume.svg";
import blueTempIcon from "../../assets/images/blue_temp.svg";
import greenTempIcon from "../../assets/images/green_temp.svg";
import yellowTempIcon from "../../assets/images/yellow_temp.svg";
import whiteStandbyIcon from "../../assets/images/standby-white.png";
import whiteFanIcon from "../../assets/images/fan-white.png";
import whiteSetupIcon from "../../assets/images/setup-white.png";
import coolingIcon from "../../assets/images/tooltip-snowflake.png";
import washingIcon from "../../assets/images/washing.png";
import diagnosticsIcon from "../../assets/images/Diagnostics.png";
import waveIcon from "../../assets/images/chiller_control_wave_icon.svg";
import standByIcon from '../../assets/images/Standby.svg';
import alertIcon from "../../assets/images/alert-icon.svg";
import $ from "jquery";
import Sidebar from "../AnnotationSidebar/AnnotationSidebar";
import { useParams } from "react-router-dom";
import {
  postManualAnnotation,
  postChillerManualAnnotation,
  DeleteManualAnnotation,
  DeleteChillerManualAnnotation,
  getUser,
} from "../../actions/actions";
import Toaster from "../Toaster";
import { toast } from "react-toastify";
import { ReactComponent as SystemIcon } from "../../assets/images/systemAlert.svg";
import { ReactComponent as ManualAlertIcon } from "../../assets/images/manualAlert.svg";
import Delete from "../../assets/images/delete.svg";
import PromptModel from "../PromptModel";
import {
  status_chiller as statusChiller,
} from "../../config";
import {
  getTempAsPerSelectedUnitSystem,
  getPressureAsPerSelectedUnitSystem,
  getVolumeAsPerSelectedUnitSystem
} from "../../helpers";
import { useDeviceContext } from '../../context/deviceContext'

$(document).on("mouseover", ".dygraph-hairline", function () {
  $(".untitled").addClass("d-none");
  $(this).next("div").find(".untitled").removeClass("d-none");
});

const GraphWrapper = styled.div`
  margin-top: 20px;
  padding: 20px 10px 40px 10px;
  border-radius: 10px;
  border: solid 2px #d6e6ff;
`;

const CheckboxText = styled.span`
  margin-left: 5px;
  margin-right: 20px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
`;

const CheckboxContainer = styled(Col)`
  align-self: center;
  padding-left: 4%;
`;

const Graph = (props) => {
  const {
    data,
    alerts,
    showAnnotation,
    sensors,
    graphId,
    t,
    washData,
    manualAnnotation,
    getAlerts,
    dropdownTime,
    isResponsiveMode,
    deviceType,
  } = props;
  const ref = useRef(null)
  const { deviceTypeGlobal } = useDeviceContext(); 
  const getTankTooltipData = (temp, vol, state) => {
    const tooltipData = {
      tankTemp: null,
      tankVolume: null,
      tankState: null,
    };

    // Set Tank Temperature for graph tooltip
    if(temp != null && !Number.isNaN(temp)) {
      const { temperature, tempUnit } = getTempAsPerSelectedUnitSystem(Number(temp), sensors.includes('EVC_INPUT_REG_SUBCOOL_TEMP'));
      tooltipData.tankTemp = (!Number.isNaN(temperature) && tempUnit) ? `${temperature} ${tempUnit}` : null;
    }

    // Set Tank Volume for graph tooltip
    if (vol != null && !Number.isNaN(vol)) {
      const { volume, volumeUnit } = getVolumeAsPerSelectedUnitSystem(Number(vol));
      tooltipData.tankVolume = (!Number.isNaN(volume) && volumeUnit) ? `${volume} ${volumeUnit}` : null;
    }

    // Set Tank State for graph tooltip
    if (state != null && !Number.isNaN(state)) {
      tooltipData.tankState = Number(state);
    }

    return tooltipData;
  };

  // TODO: Recreate this function as per new response for chilller/EVC tooltips
  const getChillerTooltipData = (chillerOutletTemp, chillerReturnTemp, processInletTemp, processOutletTemp, chillerState) => {
    const tooltipHeaderData = {};
    // Set Chiller Temperatures for graph tooltip
    Object.entries({chillerOutletTemp, chillerReturnTemp, processInletTemp, processOutletTemp}).forEach(([key, temp])=> {
      if(temp != null && !Number.isNaN(temp)) {
        const { temperature, tempUnit } = getTempAsPerSelectedUnitSystem(Number(temp), sensors.includes('EVC_INPUT_REG_SUBCOOL_TEMP'));
        tooltipHeaderData[key] = (!Number.isNaN(temperature) && tempUnit) ? `${temperature} ${tempUnit}` : null;
      }
    })

    // Set Chiller State for graph tooltip
    if (chillerState != null && !Number.isNaN(chillerState)) {
      tooltipHeaderData.chillerState = Number(chillerState);
    }

    return tooltipHeaderData;
  };

  const tooltip = (dygraphLegendFormatter, graphId, t, dropdownTime, graphValuesList = []) => {
    const currentTopOfTooltip = document.getElementsByClassName('dygraph-legend')[0]?.style?.top?.split('px')[0];
    if(currentTopOfTooltip && Number(currentTopOfTooltip) > 200) {
      document.getElementsByClassName('dygraph-legend')[0].style.top = '50px';
    }
    const date = new Date(dygraphLegendFormatter.x);
    let tankTemp = null;
    let tankVolume = null;
    let tankState = null;
    let tooltipHeaderData = {};
    if(graphId === 'hpf') {
      const tooltipData = getTankTooltipData(graphValuesList[1], graphValuesList[5], graphValuesList[6]);

      tankTemp = tooltipData.tankTemp || '--';
      tankVolume = tooltipData.tankVolume || '--';
      tankState = tooltipData.tankState || null;
    } else if(graphId === 'chiller') {
      tooltipHeaderData = getChillerTooltipData(graphValuesList[1], graphValuesList[2],graphValuesList[3],graphValuesList[4], graphValuesList[9]);
    } else if (deviceTypeGlobal === "HPF" && graphId === 'evc0') {
      const tooltipData = getTankTooltipData(graphValuesList[5], graphValuesList[6], graphValuesList[7]);

      tankTemp = tooltipData.tankTemp || '--';
      tankVolume = tooltipData.tankVolume || '--';
      tankState = tooltipData.tankState || null;

    } else if (deviceTypeGlobal === "HPF" && (graphId === 'evc1' || graphId === 'evc2')) {
            const tooltipData = getTankTooltipData(graphValuesList[2], graphValuesList[3], graphValuesList[4]);

      tankTemp = tooltipData.tankTemp || '--';
      tankVolume = tooltipData.tankVolume || '--';
      tankState = tooltipData.tankState || null;

    } else if (deviceTypeGlobal === "CHILLER" && graphId === 'evc0') {
      tooltipHeaderData = getChillerTooltipData(graphValuesList[11], graphValuesList[12],graphValuesList[13],graphValuesList[14], graphValuesList[15]);
    } else if (deviceTypeGlobal === "CHILLER" && (graphId === 'evc1' || graphId === 'evc2')) {
      tooltipHeaderData = getChillerTooltipData(graphValuesList[5], graphValuesList[6],graphValuesList[7],graphValuesList[8], graphValuesList[9]);
    }

    const milkPipelineTemp =
      graphId === "hpf"
        ? (dygraphLegendFormatter.series[1] &&
          dygraphLegendFormatter.series[1].yHTML) ||
        ""
        : "";
    const tempSensor3 =
      graphId === "hpf"
        ? (dygraphLegendFormatter.series[2] &&
          dygraphLegendFormatter.series[2].yHTML) ||
        ""
        : "";
    const tempSensor4 =
      graphId === "hpf"
        ? (dygraphLegendFormatter.series[3] &&
          dygraphLegendFormatter.series[3].yHTML) ||
        ""
        : "";

    const { chillerOutletTemp = "???", chillerReturnTemp = "???", processInletTemp = "???", processOutletTemp = "???", chillerState } = tooltipHeaderData;

    const LSP =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[0] &&
          dygraphLegendFormatter.series[0].yHTML) ||
        ""
        : "";

    const SST =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[1] &&
          dygraphLegendFormatter.series[1].yHTML) ||
        ""
        : "";

    const HSP =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[2] &&
          dygraphLegendFormatter.series[2].yHTML) ||
        ""
        : "";

    const SCT =
      graphId === "evc0"
        ? (dygraphLegendFormatter.series[3] &&
          dygraphLegendFormatter.series[3].yHTML) ||
        ""
        : "";

    const subCoolTemp =
      graphId === "evc1"
        ? (dygraphLegendFormatter.series[0] &&
          dygraphLegendFormatter.series[0].yHTML) ||
        ""
        : "";

    const evcTemp =
      graphId === "evc2"
        ? (dygraphLegendFormatter.series[0] &&
          dygraphLegendFormatter.series[0].yHTML) ||
        ""
        : "";

    const evcTemp2 =
      graphId === "evc2"
        ? (dygraphLegendFormatter.series[9] &&
          dygraphLegendFormatter.series[9].yHTML) ||
        ""
        : "";

    const displayHpfState = (
      ` ${tankState != null && tankState >= 0 && tankState <= 1000 ? `
          <div class="col-sm-4 my-2 p-0">
            <img class="img mb-1" src=${whiteStandbyIcon} alt="Standby" title="" />
            <div>${t("STANDBY")}</div>
          </div>`
        : ''}

        ${tankState === 1001 ? `
          <div class="col-md-4 col-sm-4 my-2 p-0">
            <img class="img mb-1" src=${whiteFanIcon} alt="Fan" title="" />
            <div>${t("AGITATE")}</div>
          </div>`
        : ''}

        ${tankState === 1002 || tankState === 1004 ? `
          <div class="col-md-4 col-sm-4 my-2 p-0 mode-font">
            <img class="img mb-1" src=${whiteSetupIcon} alt="Setup" title="" />
            <div>${t("SETTINGS")}</div>
          </div>`
        : ''}

        ${tankState === 1003 ? `
          <div class="col-md-4 col-sm-4 my-2 p-0 mode-font">
            <img class="img mb-1" src=${diagnosticsIcon} alt="Setup" title="" />
            <div>${t("DIAGNOSTIC")}</div>
          </div>`
        : ''}

        ${tankState >= 2000 && tankState <= 2006 ? `
          <div class="col-md-4 col-sm-4 my-2 p-0">
            <img class="img mb-1" src=${coolingIcon} alt="Cooling" title="" />
            <div>${t("COOL")}</div>
          </div>`
        : ''}

        ${tankState >= 3000 && tankState <= 4000 ? `
          <div class="col-md-4 col-sm-4 my-2 p-0">
            <img class="img mb-1" src=${washingIcon} alt="Washing" title="" />
            <div>${t("WASH")}</div>
          </div>`
        : ''}`
    )
let chillerStateIcon = '';
if(!chillerStateIcon &&  chillerState) {
  if(statusChiller[chillerState] === 'CHILLER_IDLE'  || statusChiller[chillerState] === 'CHILLING') {
    chillerStateIcon = coolingIcon;
  }
  else if (statusChiller[chillerState] === 'PUMP_IDLE' || statusChiller[chillerState] === 'PUMPING' ) {
    chillerStateIcon = waveIcon;
  }
  else if (statusChiller[chillerState] === 'ALARM'){
    chillerStateIcon = alertIcon;
  }
  else if (statusChiller[chillerState] === 'TECH_OVERRIDE'){
    chillerStateIcon = diagnosticsIcon;
  }
  else {
    chillerStateIcon = standByIcon;
  }
}
    const displayChillerState = (`
      <div class="col-sm-3 my-2 p-0">
        <div class="d-flex justify-content-center align-items-center mb-1">
          <img class="img chillerStateIcon" src=${chillerStateIcon} alt="Chiller" />
          <div style="max-width:75%;word-wrap:break-word">${chillerState ? t(statusChiller[chillerState]) : '--'}</div>
        </div>
        <div>Chiller State</div>
      </div>
    `)

    const tooltipHeader = (
      `<div class="container-fluid">
        <div class="row mb-2">
          ${
            deviceTypeGlobal === "HPF" ?
            `
              <div class="col-sm-4 right-border my-2 p-0">
                <img class="mb-1" src=${greenTempIcon} alt="" title="" />
                <div>${tankTemp}</div>
              </div>

              <div class="col-sm-4 right-border my-2 p-0">
                <img class="mb-1" src=${volumeIcon} alt="" title="" />
                <div>${tankVolume}</div>
              </div>

              ${displayHpfState}

            ` 
            :
            `
              <div class="col-sm-3 right-border my-2 p-0">
                <img class="mb-1" src=${blueTempIcon} alt="" title="" />
                <div>${chillerOutletTemp}</div>
              </div>

              <div class="col-sm-3 right-border my-2 p-0">
                <img class="mb-1" src=${greenTempIcon} alt="" title="" />
                <div>${chillerReturnTemp}</div>
              </div>

              <div class="col-sm-3 right-border my-2 p-0">
                <img class="mb-1" src=${yellowTempIcon} alt="" title="" />
                <div>${processOutletTemp}</div>
              </div>

              ${displayChillerState}
            `
          }

        </div>
      </div>`);

    return `<div class="graph-tooltip justify-content-center w-100">
      <div class="panel-box text-white">
      ${tooltipHeader}

      ${graphId === "hpf"
        ? `<div class="container-fluid p-3 tooltip-label-wrapper">
        <div class="row pb-1 ${dygraphLegendFormatter.series[1]?.isVisible ? '' : 'd-none'}">
          <div class="col-sm-1 p-0">
            <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[1].color
          }"></div>
          </div>
          <div class="col-md-8 col-sm-5 text-left">${t(
            "milk_pipeline_temperature"
          )}</div>
          <div class="col-md-3 col-sm-5 text-right">${milkPipelineTemp}</div>
        </div>

        <div class="row pb-1 ${dygraphLegendFormatter.series[2]?.isVisible ? '' : 'd-none'}">
          <div class="col-sm-1 p-0">
            <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[2].color}"></div>
          </div>
          <div class="col-md-8 col-sm-5 text-left py-1">${t(
            "temperature_sensor_3"
          )}</div>
          <div class="col-md-3 col-sm-5 text-right py-1">${tempSensor3}</div>
        </div>

        <div class="row pb-1 ${dygraphLegendFormatter.series[3]?.isVisible ? '' : 'd-none'}">
          <div class="col-sm-1 p-0">
            <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[3].color}"></div>
          </div>
          <div class="col-md-8 col-sm-5 text-left pt-1">${t("temperature_sensor_4")}</div>
          <div class="col-md-3 col-sm-5 text-right pt-1">${tempSensor4}</div>
        </div>
      </div>`
        : ""
      }
      ${graphId === "chiller" ? `
          <div class="container-fluid p-3 tooltip-label-wrapper">
            <div class="row pb-1 ${(dygraphLegendFormatter.series[0]?.isVisible || (dygraphLegendFormatter.series[0].color && chillerOutletTemp)) ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip pb-1 rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color}"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("chiller_outlet_temperature")}</div>
              <div class="col-md-3 col-sm-5 text-right pl-0">${chillerOutletTemp}</div>
            </div>
            
            <div class="row py-1 ${(dygraphLegendFormatter.series[1]?.isVisible || (dygraphLegendFormatter.series[1].color && chillerReturnTemp)) ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip rounded-circle" pl-0 style="background-color:${dygraphLegendFormatter.series[1].color}"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("chiller_return_temperature")}</div>
              <div class="col-md-3 col-sm-5 text-right pl-0">${chillerReturnTemp}</div>
            </div>
            
            <div class="row py-1 ${(dygraphLegendFormatter.series[2]?.isVisible || (dygraphLegendFormatter.series[2].color && processInletTemp)) ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[2].color}"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("process_outlet_temperature")}</div>
              <div class="col-md-3 col-sm-5 text-right pl-0">${processInletTemp}</div>
            </div>

            <div class="row py-1 ${(dygraphLegendFormatter.series[3]?.isVisible || (dygraphLegendFormatter.series[3].color && processOutletTemp)) ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[3].color}"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("process_inlet_temperature")}</div>
              <div class="col-md-3 col-sm-5 text-right pl-0">${processOutletTemp}</div>
            </div>

            <div class="row py-1 ${dygraphLegendFormatter.series[9]?.isVisible ? '' : 'd-none'}">
              <div class="col-sm-1 p-0">
                <div class="legend-tooltip rounded-circle" style="background-color:#daebff"></div>
              </div>
              <div class="col-md-8 col-sm-5 text-left">${t("active_steps")}</div>
              <div class="col-md-3 col-sm-5 text-right">${graphValuesList[10]}</div>
            </div>
          </div>`
        : ""
      }
      ${graphId === "evc0" ? `
        <div class="container-fluid p-3 tooltip-label-wrapper">
          <div class="row pb-1 ${dygraphLegendFormatter.series[0]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip pb-1 rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color}"></div>
            </div>
            <div class="col-md-7 col-sm-5 text-left">${t("lsp")}</div>
            <div class="col-md-4 col-sm-5 text-right">${LSP}</div>
          </div>
        
          <div class="row py-1 ${dygraphLegendFormatter.series[1]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[1].color}"></div>
            </div>
            <div class="col-md-7 col-sm-5 text-left">${t("sst")}</div>
            <div class="col-md-4 col-sm-5 text-right">${SST}</div>
          </div>
          
          <div class="row py-1 ${dygraphLegendFormatter.series[2]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[2].color}"></div>
            </div>
            <div class="col-md-7 col-sm-5 text-left">${t("hsp")}</div>
            <div class="col-md-4 col-sm-5 text-right">${HSP}</div>
          </div>

          <div class="row py-1 ${dygraphLegendFormatter.series[3]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[3].color}"></div>
            </div>
            <div class="col-md-7 col-sm-5 text-left">${t("sct")}</div>
            <div class="col-md-4 col-sm-5 text-right">${SCT}</div>
          </div>
        </div>`: ""
      }
      ${graphId === "evc1" ? `
        <div class="container-fluid p-3 tooltip-label-wrapper">
          <div class="row pb-1 ${dygraphLegendFormatter.series[0]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip pb-1 rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color}"></div>
            </div>
            <div class="col-md-8 col-sm-5 text-left">${t("subcool_temperature")}</div>
            <div class="col-md-3 col-sm-5 text-right">${subCoolTemp}</div>
          </div>
        </div>` : ""
      }
      ${graphId === "evc2" ? `
        <div class="container-fluid p-3 tooltip-label-wrapper">
          <div class="row pb-1 ${dygraphLegendFormatter.series[0]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[0].color}"></div>
            </div>
            <div class="col-md-8 col-sm-5 text-left">${deviceTypeGlobal === "HPF" ? t("evc_temperature") : t("accumulator_inlet_temperature")}</div>
            <div class="col-md-3 col-sm-5 text-right">${evcTemp}</div>
          </div>
        ${
          deviceTypeGlobal === "CHILLER" ?
          `<div class="row pb-1 ${dygraphLegendFormatter.series[9]?.isVisible ? '' : 'd-none'}">
            <div class="col-sm-1 p-0">
              <div class="legend-tooltip rounded-circle" style="background-color:${dygraphLegendFormatter.series[9].color}"></div>
            </div>
            <div class="col-md-8 col-sm-5 text-left">${t("accumulator_outlet_temperature")}</div>
            <div class="col-md-3 col-sm-5 text-right">${evcTemp2}</div>
          </div>` : ""
        }
        </div>` : ""
      }
      <div class="w-100 text-center pb-2 mt-2 font-weight-bold">
        <span>
                  ${dropdownTime === undefined
        ? moment(date).format("MM/DD/YY hh:mm A")
        : moment
          .tz(new Date(date), dropdownTime)
          .format("MM/DD/YY hh:mm A")
      }
        </span>
      </div>
    </div>
  </div>`;
  };
  const sensorDisplayName = {
    HPF_INPUT_REG_TEMP1: {
      name: t("tank_temperature"),
    },
    HPF_INPUT_REG_TEMP2: {
      name: t("milk_pipeline_temperature"),
    },
    HPF_INPUT_REG_TEMP3: {
      name: t("temperature_sensor_3"),
    },
    HPF_INPUT_REG_TEMP4: {
      name: t("temperature_sensor_4"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TEMP_1: {
      name: t("chiller_outlet_temperature"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TEMP_2: {
      name: t("chiller_return_temperature"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TEMP_4: {
      name: t("process_inlet_temperature"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TEMP_3: {
      name: t("process_outlet_temperature"),
    },
    CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS: {
      name: t("pump"),
      hideCheckBox: true,
    },
    CHILLER_CONTROLLER_INPUT_REG_WATER_VALVE_STATUS: {
      name: t("water_relay_status"),
    },
    CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS: {
      name: t("voltage_relay_status"),
    },
    CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS: {
      name: t("probe_sensor_status"),
    },
    HPF_INPUT_REG_VOLUME_4TO20MA_READING: {
      name: t("tank_volume"),
    },
    HPF_INPUT_REG_MAIN_STATE: {
      name: "Tank State",
      hideCheckBox: true,
    },
    EVC_INPUT_REG_TEMP_1: {
      name: deviceTypeGlobal === "HPF" ? t("evc_temperature") : t("accumulator_inlet_temperature"),
    },
    EVC_INPUT_REG_TEMP_2: {
      name: t("accumulator_outlet_temperature"),
    },
    EVC_INPUT_REG_SUBCOOL_TEMP: {
      name: t("subcool_temperature"),
    },
    EVC_INPUT_REG_LOW_SIDE_PRESSURE: {
      name: `${t("lsp")}`,
    },
    EVC_INPUT_REG_HIGH_SIDE_PRESSURE: {
      name: `${t("hsp")}`,
    },
    EVC_INPUT_REG_HI_SAT_TEMP: {
      name: `${t("sct")}`,
    },
    EVC_INPUT_REG_LO_SAT_TEMP: {
      name: `${t("sst")}`,
    },
    EVC_INPUT_REG_VALVE_POS: {
      name: `${t("valve_position")}`,
    },
    EVC_INPUT_REG_FAN_OUTPUT:{
      name: t("fan_speed"),
    },
    CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE:{
      name: t("control_status"),
    },
    CUSTOM_PARAM_ACTIVE_STEPS: {
      name: t("active_steps"),
    },
  };


  if (graphId.includes('evc')) {
    sensorDisplayName['HPF_INPUT_REG_TEMP1'].hideCheckBox = true;
    sensorDisplayName['HPF_INPUT_REG_VOLUME_4TO20MA_READING'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS'].hideCheckBox = true;
    sensorDisplayName['EVC_INPUT_REG_VALVE_POS'].hideCheckBox = true;
    sensorDisplayName['EVC_INPUT_REG_FAN_OUTPUT'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TEMP_1'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TEMP_2'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TEMP_3'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TEMP_4'].hideCheckBox = true;
    sensorDisplayName['EVC_INPUT_REG_TEMP_2'].hideCheckBox = deviceTypeGlobal === "HPF";
  }
  
  if(deviceType === "CHILLER"){
    sensorDisplayName['CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_WATER_VALVE_STATUS'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS'].hideCheckBox = true;
    sensorDisplayName['CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE'].hideCheckBox = true;
  }

  const checkboxColor = {
    [t("tank_temperature")]: {
      color: "#3ca1ff",
    },
    [t("milk_pipeline_temperature")]: {
      color: "#7ae43f",
    },
    [t("temperature_sensor_3")]: {
      color: "#b068d6",
    },
    [t("temperature_sensor_4")]: {
      color: "#efc53a",
    },
    [t("chiller_outlet_temperature")]: {
      color: "#3ca1ff",
    },
    [t("chiller_return_temperature")]: {
      color: "#7ae43f",
    },
    [t("process_outlet_temperature")]: {
      color: "#b068d6",
    },
    [t("process_inlet_temperature")]: {
      color: "#efc53a",
    },
    [t("tank_volume")]: {
      color: "#ff5c5c",
      axis: "y2",
    },
    [t("evc_temperature")]: {
      color: "#e84e4a",
    },
    [t("accumulator_inlet_temperature")]: {
      color: "#e84e4a",
    },
    [t("accumulator_outlet_temperature")]: {
      color: "#0000ff",
    },
    [t("subcool_temperature")]: {
      color: "#f3c72f",
    },
    [t("lsp")]: {
      color: "#0000ff",
    },
    [t("hsp")]: {
      color: "#ff0000",
    },
    [t("sct")]: {
      color: "#b068d6",
      axis: "y2",
    },
    [t("sst")]: {
      color: "#efc53a",
      axis: "y2",
    },
    [t("active_steps")]: {
      color: "#6db0ff33",
      axis: "y2"
    }
  };

  const TempType = [
    t("chiller_outlet_temperature"),
    t("chiller_return_temperature"),
    t("process_inlet_temperature"),
    t("process_outlet_temperature"),
    t("tank_temperature"),
    t("milk_pipeline_temperature"),
    t("evc_temperature"),
    t("subcool_temperature"),
  ];

  const [checkboxStatus, setCheckboxStatus] = useState(
    sensors.map((el) => true)
  );
  const [showDrawer, setDrawer] = useState(false);
  const [title, setTitle] = useState("");
  const [dygraph, setDygraph] = useState(null);
  const [selectedAlert, setSelectedAlert] = useState();
  const { dairyID, tankID } = useParams();
  const [open, setOpen] = useState(false);
  const user = getUser();
  const configId = deviceTypeGlobal === "HPF" ? "MANUAL_ALERTS" : "MANUAL_ALERTS_CHILLER";
  const validationTempId = "VALIDATE_TEMPERATURE";

  /** Display line in particular point */
  const pointClick = async (event, p) => {
    // just to fetch HPF or CHILLER when selected EVC
    let createdAt = p;
    if (dropdownTime !== undefined) {
      createdAt = moment.tz(new Date(p), dropdownTime).valueOf();
    }
    if (manualAnnotation) {
      try {
        if(deviceTypeGlobal === "HPF") {
          const data = {
            tank: tankID,
            dairy: dairyID,
            configDisplay: "Untitled",
            alertText: "--",
            createdAt: createdAt,
            createdBy: user.id,
          };
          const res = await postManualAnnotation(data);
          if (res.status === 200) {
            getAlerts();
          }
        } else if(deviceTypeGlobal === "CHILLER") {
          const data = {
            device: tankID,
            location: dairyID,
            configDisplay: "Untitled",
            alertText: "--",
            createdAt: createdAt,
            createdBy: user.id,
          };
          const res = await postChillerManualAnnotation(data);
          if (res.status === 200) {
            getAlerts();
          }        
        }
      } catch (err) {
        toast.dark(
          <Toaster icon="error" message={t("something_went_wrong")} />
        );
      }
    }
  };

  const replaceSensorWithDisplayName = (sensorsList, ignoreList) => {
    // Ignoring sensors that are passed in the 'ignoreList'
    if (Array.isArray(ignoreList) && ignoreList.length) {
      sensorsList = sensorsList.filter(sensor => !ignoreList.includes(sensor));
    }
    return sensorsList.map((sensor) => sensorDisplayName[sensor].name);
  }

  const handleCheckboxes = (index) => {
    if (checkboxStatus[index]) {
      checkboxStatus[index] = false;
      dygraph.setVisibility(index, false);
    } else {
      checkboxStatus[index] = true;
      dygraph.setVisibility(index, true);
    }
    setCheckboxStatus([...checkboxStatus]);
  };

  const renderGraphData = (alert) => {
    const manualItems = alert?.filter((i) => i.configKey === configId || i.configKey === validationTempId);
    let hairlines = new Dygraph.Plugins.Hairlines({
      divFiller: function (div, data) {
        const index = alert.findIndex((el) => {
          return el.createdAt === data.hairline.xval;
        });
        const isManual = manualItems.findIndex(
          (i) => i.createdAt === data.hairline.xval
        );
        $(".hairline-legend", div).html(
          `<p class="d-none untitled" style="overflow: hidden;cursor: default; white-space: nowrap;text-overflow: ellipsis;" id=${index}>${alert[index]?.configDisplay}</p>`
        );
        $(div).data({ xval: data.hairline.xval });
        div.id = "hairline-" + index;
        if (isManual > -1) {
          div.className = div.className + " manual-item";
        } else {
          div.className = div.className + " system-item";
        }
        const child = div.childNodes[0];
        child.id = index; // see .hover() below.
        child.style.display = "flex";
        child.style.flex = "1";
        child.style.justifyContent = "center";
        child.style.padding = "1rem 10px 0px 10px";
      },
    });
    // }

    /** Display HairLine in dygraph */
    let plugins = [hairlines];
    if (manualAnnotation) {
      let crossLine = new Dygraph.Plugins.Crosshair({
        direction: "vertical",
      });
      plugins.push(crossLine);
    }

    if (data) {
      let maxControlSteps = 0;
      const dataList = data.split('\n');

      for (let i = 0; i < dataList.length; i++) {
        const valuesList = dataList[i].split(',');
        // Reading the control steps value
        const controlStepsValue = valuesList[valuesList.length - 1];

        if (controlStepsValue && !isNaN(Number(controlStepsValue))) {
          maxControlSteps = Math.max(maxControlSteps, parseInt(controlStepsValue));
        }
      }

      const graphOptions = {
        height: 450,
        axisLineWidth: 0.00000000001,
        plugins: plugins,
        visibility: sensors.map((sensor) => !sensorDisplayName[sensor]?.hideCheckBox),
        legend: "follow",
        legendFormatter(dygraphLegendFormatter) {
          // Get all the sensors' data at a given time 'x'.
                    let graphValuesList = dygraphLegendFormatter.dygraph.rawData_.find((d) => d[0] === dygraphLegendFormatter.x);
          // Show tooltip only if there is some data to show other than the timestamp.
          if (!isEmpty(graphValuesList) && (graphValuesList[1] || graphValuesList[2] || graphValuesList[3] || graphValuesList[4])) {
            return tooltip(dygraphLegendFormatter, graphId, t, dropdownTime, graphValuesList);
          }

          return null;
        },
        clickCallback: pointClick,
        includeZero: true,
        series: checkboxColor,
        axes: {
          x: {
            drawAxis: true,
            drawGrid: false,
            valueFormatter(val) {
              if (dropdownTime === undefined) {
                const value = moment(val).format("MM/DD/YYYY hh:mm:ss A");
                return `<span class='timeAxis floatLeft'>Time</span><span class='floatLeft'>:</span>${value}`;
              } else {
                const value = moment
                  .tz(new Date(val), dropdownTime)
                  .format("MM/DD/YYYY hh:mm:ss A");
                return `<span class='timeAxis floatLeft'>Time</span><span class='floatLeft'>:</span>${value}`;
              }
            },
            axisLabelFormatter(val) {
              if (dropdownTime === undefined) {
                return moment(val).format("MM/DD/YY hh:mm:ss A");
              } else {
                return moment
                  .tz(new Date(val), dropdownTime)
                  .format("MM/DD/YY hh:mm:ss A");
              }
            },
          },
          y: {
            drawAxis: true,
            gridLinePattern: [4, 4],
            independentTicks: true,
            valueRange: deviceType === 'CHILLER' ? [20, null] : [null, null],
            axisLabelWidth: 63, // width of label on axis y2
            valueFormatter(val) {
              let value = val;
              const sensorArr = replaceSensorWithDisplayName(sensors);
              let unit = "";
              if (
                sensors.includes("EVC_INPUT_REG_LOW_SIDE_PRESSURE") ||
                sensors.includes("EVC_INPUT_REG_HIGH_SIDE_PRESSURE")
              ) {
                const {
                  pressure,
                  pressureUnit,
                } = getPressureAsPerSelectedUnitSystem(Number(val));
                unit = pressureUnit;
                value = pressure;
              }
              else if (sensorArr.some((sensor) => TempType.includes(sensor))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(Number(val), sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP"));
                unit = tempUnit;
                value = temperature;
              }
              return `${value}&nbsp;${unit}`;
            },
            axisLabelFormatter(val) {
              let value = val;
              const sensorArr = replaceSensorWithDisplayName(sensors);
              let unit = "";
              if (
                sensors.includes("EVC_INPUT_REG_LOW_SIDE_PRESSURE") ||
                sensors.includes("EVC_INPUT_REG_HIGH_SIDE_PRESSURE")
              ) {
                const {
                  pressure,
                  pressureUnit,
                } = getPressureAsPerSelectedUnitSystem(Number(val));
                unit = pressureUnit;
                value = pressure;
              }
              else if (sensorArr.some((sensor) => TempType.includes(sensor))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(Number(val), sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP"));
                unit = tempUnit;
                value = temperature;
              }

              return `${parseInt(value, 10)}&nbsp;${unit}`;
            },
          },
          y2: {
            drawAxis: true,
            includeZero: true, // start y2 axis from zero
            axisLabelWidth: 40, // width of label on axis y2
            independentTicks: deviceType === 'CHILLER',
            valueRange: deviceType === 'CHILLER' ? [0, null] : [null, null],
            fillGraph: deviceType === 'CHILLER',
            stepPlot: deviceType === 'CHILLER',
            valueFormatter(val) {
              const sensorArr = replaceSensorWithDisplayName(sensors);

              let unit = "";
              let value = "";
              if (sensorArr.includes(t("sst")) || sensorArr.includes(t("sct"))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(
                  Number(val),
                  sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP")
                );
                unit = tempUnit;
                value = temperature;
              } else if (deviceTypeGlobal === 'HPF' && (deviceType === 'HPF' || deviceType === "EVC")) {
                // needs to display tank volume only when globally hpf selected and graph is selected either HPF or EVC
                // it will be shown on HPF and on HPF EVC
                const {
                  volume,
                  volumeUnit,
                } = getVolumeAsPerSelectedUnitSystem(val);
                unit = volumeUnit;
                value = volume;
              } else {
                // no needs to display tank volume when globally CHILLER selected and graph is selected either CHILLER or EVC
                return '';
              }

              return `${parseInt(value, 10)} ${unit}`;
            },
            axisLabelFormatter(val) {
              if (deviceType === 'CHILLER') {
                return val;
              }

              const sensorArr = replaceSensorWithDisplayName(sensors);
              let unit = "";
              let value = "";
              if (sensorArr.includes(t("sst")) || sensorArr.includes(t("sct"))) {
                const {
                  temperature,
                  tempUnit,
                } = getTempAsPerSelectedUnitSystem(Number(val), sensors.includes("EVC_INPUT_REG_SUBCOOL_TEMP"));
                unit = tempUnit;
                value = temperature;
              } else if (deviceTypeGlobal === 'HPF' && (deviceType === 'HPF' || deviceType === "EVC")) {
                // needs to display tank volume only when globally hpf selected and graph is selected either HPF or EVC
                // it will be shown on HPF and on HPF EVC
                const {
                  volume,
                  volumeUnit,
                } = getVolumeAsPerSelectedUnitSystem(val);
                unit = volumeUnit;
                value = volume;
              } else {
                // no needs to display tank volume when globally CHILLER selected and graph is selected either CHILLER or EVC
                return '';
              }

              return `${parseInt(value, 10)} ${unit}`;
            }
          },
        },
        underlayCallback(canvas, area, g) {
          const canvasData = canvas;
          if (graphId === 'hpf' && washData !== undefined) {
            washData.forEach((value) => {
              canvasData.fillStyle = "#FDEFC0";
              const bottomLeft = g.toDomCoords(value.start, -20)[0];
              const topRight = g.toDomCoords(value.end, +20)[0];
              canvasData.fillRect(
                bottomLeft,
                area.y,
                topRight - bottomLeft,
                area.h
              );
            });
          }
        },
        drawPoints: false,
        animatedZooms: false,
        rangeSelectorHeight: 40,
        labels: ["Date"].concat(replaceSensorWithDisplayName(sensors)),
      };

      if (isResponsiveMode) {
        // Disable vertical scrolling in graph in Responsive(Mobile) view
        graphOptions.interactionModel = {
          touchstart: function (event, dygraph, context) {
            Dygraph.defaultInteractionModel.touchstart(event, dygraph, context);
            context.touchDirections = { x: true, y: false };
          },
          touchmove: function (event, dygraph, context) {
            Dygraph.defaultInteractionModel.touchmove(event, dygraph, context);
            context.touchDirections = { x: true, y: false };
          },
        };
      }

      if (deviceType === 'CHILLER') {
        graphOptions.axes.y2.ticker = function setY2Ticks(min, max, pixels, opts, dygraphRef, vals) {
          const ticks = [];
          for (let i = parseInt(min); i <= parseInt(maxControlSteps); i += 1) {
            ticks.push({ v: i, label: i });
          }
          return ticks;
        };

        graphOptions.zoomCallback = function (minDate, maxDate, yRanges) {
          if (Number(yRanges[0][0]) === 0) {
            graph.updateOptions({ valueRange: [[20, null], [0, null]] });
          }
        };
      }

      const graph = new Dygraph(document.getElementById(`graph${graphId}`), data, graphOptions);

      setCheckboxStatus((prev) => prev.map((el) => true));
      setDygraph(graph);
    }
    if (alerts && showAnnotation) {
      const hairlineValue = alerts?.map((el) => {
        return { xval: el.createdAt, interpolated: false };
      });
      const manualItems = alerts?.filter((i) => i.configKey !== configId && i.configKey !== validationTempId);
      hairlines.set(hairlineValue);
      const totalHairline = ref.current.getElementsByClassName(`hairline-info`);
      for (let i = 0; i < totalHairline.length; i++) {
        totalHairline[i].addEventListener("click", (e) => {
          setSelectedAlert(alerts[e.target.id]);
          setDrawer(true);
        });
        totalHairline[i].previousElementSibling.addEventListener(
          "mouseout",
          (e) => {
            $(`.hairline-info`).find(".untitled").addClass("d-none");
            let element = $(e.target).closest("div").parent().next();
            element.find(".untitled").removeClass("d-none");
          }
        );

        let dygraphHairline = $(totalHairline[i]).prev();
        if (
          dygraphHairline.length > 0 &&
          totalHairline[i].id !== "hairline-template"
        ) {
          var id = totalHairline[i].id;
          dygraphHairline[0].id = id;
          totalHairline[i].id = id;
          let systemId = id.split("hairline-")[1];
          let manualId = id.split("hairline-")[1];
          const isManual = manualItems.findIndex(
            (i) => i.createdAt === alerts[manualId]?.createdAt
          );

          const systemItem = manualItems.findIndex(
            (i) =>
              i.createdAt === alerts[systemId]?.createdAt &&
              i.isResolved === false
          );
          if (isManual > -1) {
            dygraphHairline[0].className =
              dygraphHairline[0].className + " system-item";
            if (systemItem > -1) {
              dygraphHairline[0].className =
                dygraphHairline[0].className + " anim-glow";
            } else {
              dygraphHairline[0].className = dygraphHairline[0].className;
            }
          } else {
            dygraphHairline[0].className =
              dygraphHairline[0].className + " manual-item";
          }
        }
      }
      const $totalDygraphHairline = $(".dygraph-hairline");
      $totalDygraphHairline.each((i, line) => {
        if (line.id !== $(".graph").attr("id")) {
          $(line).off('click');
          $(line).on('click', () => {
            let id = line.id.split("-")[1];
            setSelectedAlert(alerts[id]);
            // setTitle(alerts[id].configDisplay);
            setDrawer(true);
          });
        }
      });
    }
  };

  /** Delete Manual annotation functionality */
  const deleteAnnotation = (selectedAlert) => {
    if(deviceTypeGlobal === "HPF") {
      DeleteManualAnnotation(selectedAlert)
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);
            setDrawer(false);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("manual_annotation_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getAlerts();
          }
        })
        .catch((err) => {
          console.error(err);
        toast.dark(
          <Toaster
            icon="error"
            message={err?.response ?? t("something_went_wrong")}
          />
        );
      });
    } else {
      DeleteChillerManualAnnotation(selectedAlert)
        .then((response) => {
          if (response.status === 200) {
            setOpen(false);
            setDrawer(false);
            toast.dark(
              <Toaster
                className="text-capitalize"
                icon="notify"
                message={t("manual_annotation_deleted_successfully")}
              />,
              { autoClose: 3000, hideProgressBar: true }
            );
            getAlerts();
          }
        })
        .catch((err) => {
          console.error(err);
          toast.dark(
            <Toaster
              icon="error"
              message={err?.response ?? t("something_went_wrong")}
            />
          );
        });
    }
  };

  //Render the graph Data when graph component loads for the first time and when data or annotation value change
  useEffect(() => {
    renderGraphData(alerts);
  }, [data, showAnnotation, alerts]);

  useEffect(() => {
    renderGraphData(alerts);
  }, [manualAnnotation, dropdownTime]);

  useEffect(() => {
    renderGraphData(alerts);
  }, [showDrawer, alerts]);

  const ignoreList = (graphId.includes('evc')) ? [
      "CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS",
      "EVC_INPUT_REG_MAIN_STATE",
      "EVC_INPUT_REG_VALVE_POS",
      "EVC_INPUT_REG_FAN_OUTPUT",
      "HPF_INPUT_REG_TEMP1",
      "HPF_INPUT_REG_VOLUME_4TO20MA_READING",
      "HPF_INPUT_REG_MAIN_STATE",
      "CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_1",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_2",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_3",
      "CHILLER_CONTROLLER_INPUT_REG_TEMP_4",
      deviceTypeGlobal === "HPF" ? "EVC_INPUT_REG_TEMP_2" : ""
    ] : deviceType === "HPF" ? 
      ['HPF_INPUT_REG_MAIN_STATE'] : [
      'CHILLER_CONTROLLER_HOLD_REG_ONBOARD_RELAY_STATUS',
      'CHILLER_CONTROLLER_INPUT_REG_WATER_VALVE_STATUS',
      'CHILLER_CONTROLLER_INPUT_REG_VOLTAGE_INPUT_STATUS',
      'CHILLER_CONTROLLER_INPUT_REG_PROBE_SENSOR_STATUS',
      "CHILLER_CONTROLLER_INPUT_REG_TOP_LEVEL_STATE",
    ];

    const checkboxItems = sensors.map((key)=> {
      if (ignoreList.includes(key) || sensorDisplayName[key]?.hideCheckBox) {
        return null;
      }
      return sensorDisplayName[key]?.name;
    });

  return (
    <>
      <GraphWrapper ref={ref}>
        <Row className="mb-2">
          <CheckboxContainer lg={12}>
            {checkboxItems.map((item, index) => {
              return item && (
                /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
                  <label className="mt-1" key={index}>
                    <CustomCheckbox
                      color={checkboxColor[item]?.color}
                      onChange={() => handleCheckboxes(index)}
                      checked={checkboxStatus[index]}
                    />
                    <CheckboxText>{t(item)}</CheckboxText>
                  </label>
              );
            })}
          </CheckboxContainer>
        </Row>
        <div
          id={`graph${graphId}`}
          className="graph"
          style={{
            cursor: "pointer",
          }}
        />
        <div
          id="hairline-template"
          className={`hairline-info text-primary`}
          style={{ display: "none" }}
        >
          <div className="hairline-legend"></div>
        </div>
      </GraphWrapper>
      {showDrawer && (
        <Sidebar
          onClose={() => setDrawer(false)}
          visible={showDrawer}
          title={
            (selectedAlert.configKey === configId || selectedAlert.configKey === validationTempId) ? (
              <div className="d-flex align-items-center mb-0">
                <div className="text-primary">
                  <ManualAlertIcon className="mr-2 align-text-bottom" />
                  {t("manual_notes")}
                </div>
                <div
                  className="ml-auto mr-3 delete d-flex align-items-center justify-content-center"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Image src={Delete} />
                </div>
              </div>
            ) : (
                <div className="text-danger my-1">
                  <SystemIcon className="mr-2 align-text-bottom" />
                  {t("system_alert")}
                </div>
              )
          }
          key={selectedAlert?.id}
          alert={selectedAlert}
          getAlerts={getAlerts}
          dropdownTime={dropdownTime}
          configId={configId}
          validationTempId={validationTempId}
        />
      )}
      <PromptModel
        open={open}
        closeAlert={() => {
          setOpen(false);
        }}
        modelContent={t("delete_annotation_msg")}
        className="text-black mt-3"
        callback={() => deleteAnnotation(selectedAlert.id)}
      />
    </>
  );
};

export default withTranslation()(Graph);
